<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4"></div>
    </section>

    <section class="section section-skew">
      <div class="container col-9">
        <card shadow class="card-profile " no-body>
          <b-row>
            <b-col>
              <div class="mb-4">
                <b-alert :show="show" :variant="color" dismissible>
                  {{ mss_alert }}
                </b-alert>
              </div>
              <b-card-header>
                <h4>Selo Agricultura Familiar - SAF</h4>
              </b-card-header>
              <card-body>
                <p class="card-text mt-3">
                  <strong>Doces, geleias, polpas de frutas, filés de pescado, aves abatidas, ovos caipiras,
                    hortaliças</strong> e tantos
                  outros produtos beneficiados da agricultura familiar do Rio Grande do Norte que receberem autorização
                  para uso do Selo da Agricultura Familiar (SAF-RN) são <strong>isentos de ICMS</strong>,
                  garantindo-lhes <strong>um lugar de
                    destaque, com muito mais competitividade, no mercado potiguar.</strong> Confira o texto da lei
                  <strong><a target="_blank"
                      href="https://leisestaduais.com.br/rn/lei-ordinaria-n-11007-2021-rio-grande-do-norte-institui-o-selo-dos-produtos-da-agricultura-familiar-do-rio-grande-do-norte-saf-rn-e-da-outras-providencias">neste
                      link</a></strong> e o
                  decreto <strong><a target="_blank"
                      href="http://diariooficial.rn.gov.br/dei/dorn3/docview.aspx?id_jor=00000001&data=20220825&id_doc=784006">neste
                      link</a></strong>.
                </p>
                <p class="card-text">
                  <strong>O SAF-RN</strong> tem como objetivo possibilitar aos agricultores e familiares a agregação de
                  valor à produção
                  agropecuária, às atividades de pesca, aquicultura e extrativista vegetal, com vistas ao
                  desenvolvimento rural sustentável, à promoção da segurança alimentar e nutricional da população e ao
                  incremento à geração de trabalho, emprego e renda.Instrumento importante que contribui para a
                  ampliação
                  do acesso aos mercados de produtos da agricultura familiar, reforçando a estratégia que o Governo do
                  RN
                  desenvolve através do Programa Estadual de Apoio aos Circuitos Curtos de Comercialização da
                  Agricultura
                  Familiar <strong>(Mais Mercados)</strong> e doPrograma Estadual de Compras da Agricultura Familiar e
                  Economia Solidária
                  <strong>(Pecafes). <a href="#">Leia a lei</a></strong> na íntegra.
                </p>
                <h5>
                  Secretaria de Estado do Desenvolvimento Rural e Agricultura Familiar – <strong>SEDRAF</strong> e
                  Secretaria de estado
                  da Tributação - <strong>SET</strong>.
                </h5>
                <p>
                  A medida se tornou possível graças à aprovação de <strong>Lei Estadual Nº 11.007/21,</strong> de
                  autoria do Dep.
                  Estadual Souza e sancionada pela governadora Fátima Bezerra em outubro de 2021, que beneficia
                  <strong>agricultores(as) familiares, revendedores dos produtos e os consumidores.</strong>
                </p>
                <p>
                  No dia 30 de agosto de 2022, o Governo do RN, na presença da governadora Fátima Bezerra e de de
                  representantes de movimentos sociais, lançou a <strong>arte do selo</strong> e regulamentou seu uso,
                  com base em um
                  <strong><a href="#" target="_blank">neste decreto</a></strong>, publicado no Diário Oficial do RN.
                </p>
                <p>
                  São considerados <strong>beneficiários do SAF/RN:</strong> agricultores familiares, empreendedores
                  familiares rurais,
                  silvicultores, aquicultores, extrativistas, povos indígenas, quilombolas e pescadores artesanais
                  profissionais, de forma individual ou coletiva, que atendam aos requisitos do <strong>art. 3º da Lei
                    Federal
                    nº 11.326,</strong> de 24 de julho de 2006, assim como os acampados da reforma agrária.
                </p>
                <p><strong>Vantagens tributárias</strong>– O <strong>produto</strong> das <strong>unidades de
                    beneficiamento</strong> e <strong>agroindústrias</strong> da Agricultura
                  Familiar <strong>passa a ser isento</strong> de ICMS para quem o adquirir para revender das
                  <strong>associações e cooperativas,
                    bem como dos demais beneficiários,</strong> que possuírem o SAF-RN.
                </p>
                <h6><strong>Critérios para emissão e utilização do SAF- RN:</strong></h6>
                <section>
                  <ul>
                    <li>
                      O SAF/RN será emitido pela Secretaria de Estado do Desenvolvimento Rural e Agricultura Familiar
                      (SEDRAF), para utilização em produtos oriundos da agricultura familiar, mediante solicitação dos
                      interessados.
                    </li>
                    <li>
                      Os interessados poderão ser pessoas físicas ou jurídicas portadoras de DAP ou CAF e cadastradas
                      no SIRAF-NE.
                    </li>
                    <li>
                      Na hipótese de agricultores familiares acampados, poderá ser emitido documento específico que
                      dará acesso ao SAF-RN.
                    </li>
                  </ul>
                  <ul>
                    <strong>Orientações para identificação e uso SAF/RN:</strong>
                    <li>
                      O SAF-RN será identificado com uma imagem específica, conforme disposto no <strong><a target="_blank" href="#">Manual de
                      Identificação Visual do SAF/RN</a></strong>. O selo poderá ser adesivado no produto ou
                      impresso em seu rótulo ou embalagem.
                    </li>
                  </ul>
                </section>

              </card-body>
            </b-col>
          </b-row>
          
        </card>


        <div>
          <vue-recaptcha ref="invisibleRecaptcha" @verify="onVerify" @expired="onExpired" size="invisible"
            :loadRecaptchaScript="true" :sitekey="recaptcha">
          </vue-recaptcha>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { baseApiUrl, gRecapctha } from "@/global";
import VueRecaptcha from "vue-recaptcha";
import axios from "axios";
import loadpage from "../components/loadPage.vue";


export default {
  name: "stamp",
  components: { loadpage, VueRecaptcha },
  data() {
    return {
      recaptcha: gRecapctha,
      chave: "",
      cont: 40,
      mss_alert: "",
      color: "primary",
      show: false,

      loading: false,
      post: null,
      error: null,
      errorMessage: "Ocorreu um erro ao processar sua requisição...",
    };
  },

  methods: {
    validate() {
      this.$refs.invisibleRecaptcha.execute();
    },
    onVerify(responseRecaptcha) {
      this.color = "primary";
      this.show = false;

      console.log("Verify: " + responseRecaptcha);

      const url = baseApiUrl + "/homologations/certificate-validation/";
      let payload = {
        code: this.chave,
        recaptcha: responseRecaptcha,
      };

      const response = axios.post(url, payload);
      response.then((resp) => {
        this.resetRecaptcha();
        console.log(resp.data.is_valid);

        if (resp.data.is_valid) {
          this.mss_alert =
            "Certificado Válido! Chave informada:  " + this.chave;
          this.color = "success";
          this.show = true;
          this.chave = "";
        } else {
          this.mss_alert =
            "Certificado Inválido! Chave informada: \n" + this.chave;
          this.color = "danger";
          this.show = true;
          this.chave = "";
        }
      });
      response.catch((err) => {
        this.resetRecaptcha();
        this.mss_alert =
          "Não foi possível validar a chave informada! Erro: " +
          err.response.status;
        this.color = "danger";
        this.show = true;
        this.chave = "";
      });
    },
    onExpired: function () {
      console.log("Expired");
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.$refs.invisibleRecaptcha.reset(); // Direct call reset method
    },
  },
  mounted() {
    if (this.$route.query.chave) {
      this.chave = this.$route.query.chave;
      this.validate();
    }
  },
};
</script>

<style scoped>
#input {
  font-size: 1.6em;
}

h5 {
  margin-top: 20px;
  margin-bottom: 15px;
}

h6 {
  margin-top: 25px;
  margin-bottom: 10px;
}

.loading {
  height: 500px;
  text-align: center;
  padding-top: 50px;
}

.profile-page .card-profile {
    margin-top: -400px !important;
    padding: 5px 25px 10px 25px;
 
  }


@media only screen and (max-width: 1920px) {

  /*Tablets [601px -> 1200px]*/
  p {
    font-size: 1rem !important;
    text-indent: 20px;
  }

}

@media only screen and (max-width: 600px) {


  /*Big smartphones [426px -> 600px]*/
  p {
    font-size: 14px !important;
  }

  p {
    text-indent: 15px;
  }
}

@media only screen and (max-width: 425px) {

  /*Small smartphones [325px -> 425px]*/
  p {
    font-size: 12px !important;
    text-indent: 10px;
  }
}
</style>