var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-3 col-6" }, [
      _c(
        "small",
        { staticClass: "d-block text-uppercase font-weight-bold mb-4" },
        [_vm._v("Image")]
      ),
      _c("img", {
        directives: [
          {
            name: "lazy",
            rawName: "v-lazy",
            value: "img/theme/team-1-800x800.jpg",
            expression: "'img/theme/team-1-800x800.jpg'",
          },
        ],
        staticClass: "img-fluid rounded shadow",
        staticStyle: { width: "150px" },
        attrs: { alt: "Rounded image" },
      }),
    ]),
    _c("div", { staticClass: "col-sm-3 col-6" }, [
      _c(
        "small",
        { staticClass: "d-block text-uppercase font-weight-bold mb-4" },
        [_vm._v("Circle Image")]
      ),
      _c("img", {
        directives: [
          {
            name: "lazy",
            rawName: "v-lazy",
            value: "img/theme/team-2-800x800.jpg",
            expression: "'img/theme/team-2-800x800.jpg'",
          },
        ],
        staticClass: "img-fluid rounded-circle shadow",
        staticStyle: { width: "150px" },
        attrs: { alt: "Circle image" },
      }),
    ]),
    _c("div", { staticClass: "col-sm-3 col-6 mt-5 mt-sm-0" }, [
      _c(
        "small",
        { staticClass: "d-block text-uppercase font-weight-bold mb-4" },
        [_vm._v("Raised")]
      ),
      _c("img", {
        directives: [
          {
            name: "lazy",
            rawName: "v-lazy",
            value: "img/theme/team-3-800x800.jpg",
            expression: "'img/theme/team-3-800x800.jpg'",
          },
        ],
        staticClass: "img-fluid rounded shadow-lg",
        staticStyle: { width: "150px" },
        attrs: { alt: "Raised image" },
      }),
    ]),
    _c("div", { staticClass: "col-sm-3 col-6 mt-5 mt-sm-0" }, [
      _c(
        "small",
        { staticClass: "d-block text-uppercase font-weight-bold mb-4" },
        [_vm._v("Circle Raised")]
      ),
      _c("img", {
        directives: [
          {
            name: "lazy",
            rawName: "v-lazy",
            value: "img/theme/team-4-800x800.jpg",
            expression: "'img/theme/team-4-800x800.jpg'",
          },
        ],
        staticClass: "img-fluid rounded-circle shadow-lg",
        staticStyle: { width: "150px" },
        attrs: { alt: "Raised circle image" },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }