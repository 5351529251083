var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profile-page" }, [
    _vm._m(0),
    _c("section", { staticClass: "section section-skew" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "card",
            {
              staticClass: "card-profile mt--300",
              attrs: { shadow: "", "no-body": "" },
            },
            [
              _c("div", { staticClass: "px-4" }, [
                _c("div", { staticClass: "row justify-content-center" }, [
                  _c("div", { staticClass: "col-lg-3 order-lg-2" }, [
                    _c("div", { staticClass: "card-profile-image" }, [
                      _c("a", { attrs: { href: "#" } }, [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: "img/theme/team-4-800x800.jpg",
                              expression: "'img/theme/team-4-800x800.jpg'",
                            },
                          ],
                          staticClass: "rounded-circle",
                        }),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-lg-4 order-lg-3 text-lg-right align-self-lg-center",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card-profile-actions py-4 mt-lg-0" },
                        [
                          _c(
                            "base-button",
                            {
                              staticClass: "mr-4",
                              attrs: { type: "info", size: "sm" },
                            },
                            [_vm._v("Connect")]
                          ),
                          _c(
                            "base-button",
                            {
                              staticClass: "float-right",
                              attrs: { type: "default", size: "sm" },
                            },
                            [_vm._v("Message")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "col-lg-4 order-lg-1" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-profile-stats d-flex justify-content-center",
                      },
                      [
                        _c("div", [
                          _c("span", { staticClass: "heading" }, [
                            _vm._v("22"),
                          ]),
                          _c("span", { staticClass: "description" }, [
                            _vm._v("Friends"),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "heading" }, [
                            _vm._v("10"),
                          ]),
                          _c("span", { staticClass: "description" }, [
                            _vm._v("Photos"),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "heading" }, [
                            _vm._v("89"),
                          ]),
                          _c("span", { staticClass: "description" }, [
                            _vm._v("Comments"),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "text-center mt-5" }, [
                  _c("h3", [
                    _vm._v("Jessica Jones\n                            "),
                    _c("span", { staticClass: "font-weight-light" }, [
                      _vm._v(", 27"),
                    ]),
                  ]),
                  _c("div", { staticClass: "h6 font-weight-300" }, [
                    _c("i", { staticClass: "ni location_pin mr-2" }),
                    _vm._v("Bucharest, Romania"),
                  ]),
                  _c("div", { staticClass: "h6 mt-4" }, [
                    _c("i", { staticClass: "ni business_briefcase-24 mr-2" }),
                    _vm._v("Solution Manager - Creative Tim Officer"),
                  ]),
                  _c("div", [
                    _c("i", { staticClass: "ni education_hat mr-2" }),
                    _vm._v("University of Computer Science"),
                  ]),
                ]),
                _c("div", { staticClass: "mt-5 py-5 border-top text-center" }, [
                  _c("div", { staticClass: "row justify-content-center" }, [
                    _c("div", { staticClass: "col-lg-9" }, [
                      _c("p", [
                        _vm._v(
                          "An artist of considerable range, Ryan — the name taken by Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs and records all of his own music, giving it a warm, intimate feel with a solid groove structure. An artist of considerable range."
                        ),
                      ]),
                      _c("a", { attrs: { href: "#" } }, [_vm._v("Show more")]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "section-profile-cover section-shaped my-0" },
      [
        _c(
          "div",
          {
            staticClass: "shape shape-style-1 shape-primary shape-skew alpha-4",
          },
          [
            _c("span"),
            _c("span"),
            _c("span"),
            _c("span"),
            _c("span"),
            _c("span"),
            _c("span"),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }