var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row justify-content-center" }, [
    _c(
      "div",
      { staticClass: "col-lg-6" },
      [
        _vm._m(0),
        _c("tabs", {
          staticClass: "flex-column flex-md-row",
          attrs: { fill: "" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ activeTabIndex }) {
                return _c(
                  "card",
                  { attrs: { shadow: "" } },
                  [
                    _c(
                      "tab-pane",
                      { key: "tab1" },
                      [
                        _c("template", { slot: "title" }, [
                          _c("i", {
                            staticClass: "ni ni-cloud-upload-96 mr-2",
                          }),
                          _vm._v("Home\n                    "),
                        ]),
                        _c("p", { staticClass: "description" }, [
                          _vm._v(
                            "Raw denim you probably haven't heard of them jean shorts\n                        Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache\n                        cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro\n                        keffiyeh dreamcatcher synth."
                          ),
                        ]),
                        _c("p", { staticClass: "description" }, [
                          _vm._v(
                            "Raw denim you probably haven't heard of them jean shorts\n                        Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse."
                          ),
                        ]),
                      ],
                      2
                    ),
                    _c(
                      "tab-pane",
                      { key: "tab2" },
                      [
                        _c("template", { slot: "title" }, [
                          _c("i", { staticClass: "ni ni-bell-55 mr-2" }),
                          _vm._v("Profile\n                    "),
                        ]),
                        _c("p", { staticClass: "description" }, [
                          _vm._v(
                            "Cosby sweater eu banh mi, qui irure terry richardson ex\n                        squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan\n                        american apparel, butcher voluptate nisi qui."
                          ),
                        ]),
                      ],
                      2
                    ),
                    _c(
                      "tab-pane",
                      { key: "tab3" },
                      [
                        _c("template", { slot: "title" }, [
                          _c("i", {
                            staticClass: "ni ni-calendar-grid-58 mr-2",
                          }),
                          _vm._v("Messages\n                    "),
                        ]),
                        _c("p", { staticClass: "description" }, [
                          _vm._v(
                            "Raw denim you probably haven't heard of them jean shorts\n                        Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache\n                        cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro\n                        keffiyeh dreamcatcher synth."
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                )
              },
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-lg-6 mt-5 mt-lg-0" },
      [
        _vm._m(1),
        _c(
          "tabs",
          { staticClass: "flex-column flex-md-row", attrs: { fill: "" } },
          [
            _c(
              "card",
              { attrs: { shadow: "" } },
              [
                _c("tab-pane", { attrs: { title: "Home" } }, [
                  _c("p", { staticClass: "description" }, [
                    _vm._v(
                      "Raw denim you probably haven't heard of them jean shorts\n                        Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache\n                        cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro\n                        keffiyeh dreamcatcher synth."
                    ),
                  ]),
                  _c("p", { staticClass: "description" }, [
                    _vm._v(
                      "Raw denim you probably haven't heard of them jean shorts\n                        Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse."
                    ),
                  ]),
                ]),
                _c("tab-pane", { attrs: { title: "Profile" } }, [
                  _c("p", { staticClass: "description" }, [
                    _vm._v(
                      "Cosby sweater eu banh mi, qui irure terry richardson ex\n                        squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan\n                        american apparel, butcher voluptate nisi qui."
                    ),
                  ]),
                ]),
                _c("tab-pane", { attrs: { title: "Messages" } }, [
                  _c("p", { staticClass: "description" }, [
                    _vm._v(
                      "Raw denim you probably haven't heard of them jean shorts\n                        Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache\n                        cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro\n                        keffiyeh dreamcatcher synth."
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("small", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("With icons"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("small", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("With text"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }