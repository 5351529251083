<template>
  <div>
    <highcharts
      :constructorType="'mapChart'"
      class="hc"
      :options="chartOptions"
      ref="chart"
      id="container"
    ></highcharts>
  </div>
</template>

<script>
import axios from "axios";
import { baseURL } from "../../global";

export default {
  props: {
    /* loading: {
      required: true,
      type: Boolean,
      default: true,
    }, */
    mapAddress: {
      required: true,
      type: Number,
      default: 2,
    },
    category: {
      required: false,
      type: String,
      //default: "Ofertantes",
    },
    data: Object,
    init: {
      required: true,
      type: Number,
      default: 0,
    },
    end: {
      required: true,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      //mapaVelho: null,
      mapaNovo: {},
      //loading: true,
      //cidades: Cidades,
      chartOptions: {
        lang: {
          loading: "Carregando...",
          downloadCSV: "Baixar CSV",
          downloadJPEG: "Baixar JPEG",
          downloadPDF: "Baixar PDF",
          downloadPNG: "Baixar PNG",
          downloadSVG: "Baixar SVG",
          downloadXLS: "Baixar XLS",
          drillUpText: "Voltar DRILL",
          exitFullscreen: "Sair da tela cheia..",
          numericSymbols: ["m", "M", "B", "T", "P", "E"],
          printChart: "Imprimir",
          viewFullscreen: "Ver em tela cheia",
          zoomIn: "Aumentar",
          zoomOut: "Diminuir",
          contextButtonTitle: "Opções",
        },
        chart: {
          map: this.mapaNovo,
          //backgroundColor: "rgba(0,0,0,0)",
          events: {
            load() {
              this.showLoading();
            },
          },
          //zoomType: "xy",
          //panning: false,
          //panKey: "shift",
        },

        title: {
          text: "",
        },

        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
        },
        navigation: {
          buttonOptions: {
            align: "right",
            enabled: true,
          },
        },
        colorAxis: {
          min: 0,
        },
        legend: {
          layout: "horizontal",
          align: "right",
          verticalAlign: "bottom",
          title: {
            text: "Quantidade",
          },
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          map: {
            allAreas: false,
            //joinBy: ["iso-a2", "code"],
          },
        },
        /* yAxis: {
          events: {
            setExtremes(e) {
              if (e.trigger === "pan") {
                console.log("Y " + e.min, e.max);
              }
            },
          },
        },
        xAxis: {
          events: {
            setExtremes(e) {
              if (e.trigger === "pan") {
                console.log("X -> " + e.min, e.max);
              }
            },
          },
        }, */
        series: [
          {
            name: this.category,
            states: {
              hover: {
                //color: "#fb6340",
                borderColor: "#303030",
                borderWidth: 2,
              },
            },
            dataLabels: {
              //enabled: true,
              format: "{point.name}",
            },
            /* tooltip: {
                pointFormat: '{point.name}: {point.value}/km²'
            }, */
            allAreas: false,

            data: [],
          },
        ],
      },
    };
  },
  watch: {
    /* mapAddress: function () {
      //console.log(this.data);
      console.log("load map");
      this.loadNewMap(this.mapAddress);
    }, */

    /* loading: function () {
      //console.log(this.data);
      //console.log("load map");
      if (this.loading) {
        this.$refs.chart.chart.showLoading();
      } else {
        this.$refs.chart.chart.hideLoading();
      }
    }, */
    init: function () {
      this.$refs.chart.chart.showLoading();
    },
    end: function () {
      //console.log(this.end);
      //console.log("load map");
      this.loadNewMap(this.mapAddress);
    },
  },
  methods: {
    loadNewMap: function (newMap) {
      this.loading = true;
      let json;
      if (newMap == 0) {
        newMap = 2;
      }
      axios
        .get(baseURL + "maps/" + newMap + ".json")
        //.get("http://localhost:8080/" + "maps/" + newMap + ".json")
        .then((response) => {
          json = response.data;
          //console.log(json);
          let newData = [];
          let stop = 0;
          this.mapaNovo = json;

          //console.log(this.data);
          if (newMap == 2) {
            for (let i in this.mapaNovo.features) {
              //console.log(this.mapaNovo.features[i].properties.codarea);
              //for (let j in this.mapaNovo.features[i].properties.codarea) {
              //console.log(this.data[this.mapaNovo.features[i].properties.codarea]);
              if (
                this.data[this.mapaNovo.features[i].properties.codarea] !=
                undefined
              ) {
                newData.push([
                  this.mapaNovo.features[i].properties.codarea,
                  this.data[this.mapaNovo.features[i].properties.codarea],
                ]);
              } else {
                newData.push([this.mapaNovo.features[i].properties.codarea, 0]);
              }
              //}
            }
          } else {
            newData = [];
            //console.log(this.mapaNovo.features);
            for (let i in this.mapaNovo.features) {
              //console.log(this.mapaNovo.features[i].properties.codarea);
              for (let j in this.data) {
                //console.log(this.data[j]);
                if (j == this.mapaNovo.features[i].properties.codarea) {
                  stop = j;
                  //console.log(stop);
                }
              }
              if (stop > 0) {
                newData.push([
                  //this.mapaNovo.features[j].properties.codarea,
                  stop,
                  this.data[stop],
                ]);
                stop = 0;
              } else {
                newData.push([this.mapaNovo.features[i].properties.codarea, 0]);
              }
            }
          }
          //console.log(newData);

          //=========== transformação do mapa do igbe para hc ============
          //for (let i in this.mapaNovo.features) {
          //console.log(this.mapaNovo.features[i].properties.codarea)
          //this.mapaNovo.features[i].properties["hc-middle-x"] = 0.5;
          //this.mapaNovo.features[i].properties["hc-middle-y"] = 0.5;
          //this.mapaNovo.features[i].properties["hc-key"] =
          //this.mapaNovo.features[i].properties.codarea;
          //for (let j in this.cidades) {
          //if (
          //this.mapaNovo.features[i].properties.codarea ==
          //this.cidades[j].fields.ibge_id
          //) {
          //console.log(this.cidades[j].fields.ibge_id)
          //this.mapaNovo.features[i].properties["name"] =
          //this.cidades[j].fields.name;
          //newData.push([
          //  this.mapaNovo.features[i].properties.codarea,
          //  Math.random() * 100,
          //]);
          //}
          //}

          //}
          //console.log(this.mapaNovo)
          //=========== transformação do mapa do igbe para hc ============

          //this.chartOptions.chart.map = null;
          this.chartOptions.series[0].data = newData;
          this.chartOptions.series[0].name = this.category;
          //this.$refs.chart.chart.series[0].update({name: this.category, data: newData}, true);
          this.chartOptions.chart.map = this.mapaNovo;

          //console.log(this.$refs.chart.chart.xAxis[0]);
          //this.$refs.chart.chart.render();

          //this.$refs.chart.chart.mapZoom(0);
          //this.$refs.chart.chart.yAxis[0].zoom(1, 18);
          //this.$refs.chart.chart.xAxis[0].zoom(56.9876087398374, -25.012391260162598);
          //this.$refs.chart.chart.xAxis[0].setExtremes(-39.30052472527589 -34.30052472527589);
          this.$refs.chart.chart.yAxis[0].zoom(1.0495, 18.3372);
          this.$refs.chart.chart.xAxis[0].zoom(
            -56.296794715447156,
            -24.85370528455285
          );
          if (this.mapAddress == 21) {
            this.$refs.chart.chart.yAxis[0].zoom(0, 11);
            this.$refs.chart.chart.xAxis[0].zoom(-48, -42);
          } else if (this.mapAddress == 22) {
            this.$refs.chart.chart.yAxis[0].zoom(2, 11);
            this.$refs.chart.chart.xAxis[0].zoom(-46, -40);
          } else if (this.mapAddress == 23) {
            this.$refs.chart.chart.yAxis[0].zoom(2, 8);
            this.$refs.chart.chart.xAxis[0].zoom(-45, -34);
          } else if (this.mapAddress == 24) {
            this.$refs.chart.chart.yAxis[0].zoom(5, 7);
            this.$refs.chart.chart.xAxis[0].zoom(-39, -34);
          } else if (this.mapAddress == 25) {
            this.$refs.chart.chart.yAxis[0].zoom(5.8, 8.8);
            this.$refs.chart.chart.xAxis[0].zoom(-39.5, -34);
          } else if (this.mapAddress == 26) {
            this.$refs.chart.chart.yAxis[0].zoom(6, 11.4);
            this.$refs.chart.chart.xAxis[0].zoom(-43, -33);
          } else if (this.mapAddress == 27) {
            this.$refs.chart.chart.yAxis[0].zoom(8, 11);
            this.$refs.chart.chart.xAxis[0].zoom(-39, -34);
          } else if (this.mapAddress == 28) {
            this.$refs.chart.chart.yAxis[0].zoom(9.4, 12);
            this.$refs.chart.chart.xAxis[0].zoom(-40, -35);
          } else if (this.mapAddress == 29) {
            this.$refs.chart.chart.yAxis[0].zoom(8, 20);
            this.$refs.chart.chart.xAxis[0].zoom(-48, -32);
          } else {
            //this.$refs.chart.chart.yAxis[0].zoom(0, 20);
            //this.$refs.chart.chart.xAxis[0].zoom(-48, -34);
            this.$refs.chart.chart.yAxis[0].zoom(1.0495, 18.3372);
            this.$refs.chart.chart.xAxis[0].zoom(
              -56.296794715447156,
              -24.85370528455285
            );
          }

          this.$refs.chart.chart.hideLoading();
          //this.loading = false;
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    //this.loadNewMap(2)
    //this.$refs.chart.chart.redraw();
  },
};
</script>

<style>
#container {
  height: 600px;
  min-width: 310px;
  max-width: 1000px;
  margin: 0 auto;
}
</style>
