var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section-hero section-shaped my-0" }, [
    _c("div", { staticClass: "shape shape-style-1 shape-primary" }),
    _c(
      "div",
      { staticClass: "container shape-container d-flex align-items-center" },
      [
        _c("div", { staticClass: "col px-0" }, [
          _c(
            "div",
            { staticClass: "row justify-content-center align-items-center" },
            [
              _c("div", { staticClass: "col-lg-7 text-center pt-lg" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  staticStyle: { width: "200px" },
                  attrs: { src: "img/brand/white.png" },
                }),
                _c("p", { staticClass: "lead text-white mt-4 mb-5" }, [
                  _vm._v(
                    "A beautiful Design System for Bootstrap 4. It's Free and Open Source."
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "btn-wrapper" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "mb-3 mb-sm-0",
                        attrs: {
                          tag: "a",
                          href: "https://demos.creative-tim.com/vue-argon-design-system/documentation",
                          type: "info",
                          icon: "fa fa-code",
                        },
                      },
                      [
                        _vm._v(
                          "\n                            Components\n                        "
                        ),
                      ]
                    ),
                    _c(
                      "base-button",
                      {
                        staticClass: "mb-3 mb-sm-0",
                        attrs: {
                          tag: "a",
                          href: "https://www.creative-tim.com/product/vue-argon-design-system",
                          type: "white",
                          icon: "ni ni-cloud-download-95",
                        },
                      },
                      [
                        _vm._v(
                          "\n                            Download Vue\n                        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._m(0),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "row align-items-center justify-content-around stars-and-coded",
      },
      [
        _c("div", { staticClass: "col-sm-4" }, [
          _c("span", { staticClass: "text-white alpha-7 ml-3" }, [
            _vm._v("Star us on"),
          ]),
          _c(
            "a",
            {
              attrs: {
                href: "https://github.com/creativetimofficial/argon-design-system",
                target: "_blank",
                title: "Support us on Github",
              },
            },
            [
              _c("img", {
                staticStyle: { height: "22px", "margin-top": "-3px" },
                attrs: { src: "img/brand/github-white-slim.png" },
              }),
            ]
          ),
        ]),
        _c("div", { staticClass: "col-sm-4 mt-4 mt-sm-0 text-right" }, [
          _c("span", { staticClass: "text-white alpha-7" }, [
            _vm._v("Coded by"),
          ]),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.creative-tim.com",
                target: "_blank",
                title: "Creative Tim - Premium Bootstrap Themes and Templates",
              },
            },
            [
              _c("img", {
                staticClass: "ml-3",
                staticStyle: { height: "30px" },
                attrs: { src: "img/brand/creativetim-white-slim.png" },
              }),
            ]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }