<template>
  <header class="header-global">
    <base-nav class="navbar-main" transparent type="" effect="light" expand>
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img src="img/brand/logo.png" alt="logo" />
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="#">
            <img src="img/brand/logo.png" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <li>
          <router-link to="/vitrine" class="nav-link" role="button">Vitrine</router-link>
        </li>
        <li>
          <router-link to="/bancodeprecos" class="nav-link" role="button">Banco de Preços</router-link>
        </li>
        <li>
          <router-link to="/biblioteca" class="nav-link" role="button">Biblioteca</router-link>
        </li>
        <li>
          <router-link to="/Validations" class="nav-link" role="button">Validações</router-link>
        </li>
        <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
          <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
            <i class="ni ni-ui-04 d-lg-none"></i>
            <span class="nav-link-inner--text">Manuais</span>
          </a>
          <li>
            <div class="dropdown-menu-inner">
              <a href="docs/Manual_Usuario_SIRAF-NE_V1.pdf" target="_blank" class="media d-flex align-items-center">
                <div class="
                  icon icon-shape
                  bg-gradient-primary
                  rounded-circle
                  text-white
                ">
                  <i class="ni ni-single-copy-04"></i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-primary mb-md-1">Manual do Usuário</h6>
                  <p class="description d-none d-md-inline-block mb-0">
                    Download do Manual do Usuário Externo do SIRAF-NE na versão
                    1.0 que auxilia na criação de conta de usuários e
                    preenchimento dos perfis.
                  </p>
                </div>
              </a>
            </div>
          </li>
          <li>
            <div class="dropdown-menu-inner">
              <a href="docs/SIRAF-NE_Política_de_Privacidade.pdf" target="_blank"
                class="media d-flex align-items-center">
                <div class="
                  icon icon-shape
                  bg-gradient-success
                  rounded-circle
                  text-white
                ">
                  <i class="ni ni-key-25"></i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-success mb-md-1">
                    Política de Privacidade
                  </h6>
                  <p class="description d-none d-md-inline-block mb-0">
                    Este documento possui informações sobre o quais tratamentos sobre os
                    dados pessoais dos usuários são realizados.
                  </p>
                </div>
              </a>
            </div>
          </li>
         
          <li>
            <div class="dropdown-menu-inner">
              <a :href="baseURL + 'api/private'" target="_blank" class="media d-flex align-items-center">
                <div class="
                  icon icon-shape
                  bg-gradient-warning
                  rounded-circle
                  text-white
                ">
                  <i class="ni ni-book-bookmark"></i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-warning mb-md-1">
                    API Privada
                  </h6>
                  <p class="description d-none d-md-inline-block mb-0">
                    Documentação da API Privada do SIRAF-NE
                  </p>
                </div>
              </a>
            </div>
          </li>
        </base-dropdown>
       

        <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
          <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
            <i class="ni ni-ui-04 d-lg-none"></i>
            <span class="nav-link-inner--text">Outros</span>
          </a>
          <li>
            <div class="dropdown-menu-inner">
              <a href="/RN-SAF"  class="media d-flex align-items-center">
                <div class="
                  icon icon-shape
                  bg-gradient-primary
                  rounded-circle
                  text-white
                ">
                  <i class="ni ni-single-copy-04"></i>
                </div>
                <div class="media-body ml-3">
                  <h6 class="heading text-primary mb-md-1">Selo SAF-RN</h6>
                  <p class="description d-none d-md-inline-block mb-0">
                    Selo Agricultura Familiar
                  </p>
                </div>
              </a>
            </div>
          </li>
         
        </base-dropdown>

        <!-- <base-dropdown tag="li" class="nav-item">
          <a
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
            role="button"
          >
            <i class="ni ni-collection d-lg-none"></i>
            <span class="nav-link-inner--text">Biblioteca</span>
          </a>
          <router-link to="#" class="dropdown-item">Normas</router-link>
          <router-link to="#" class="dropdown-item">Editais</router-link>
          <router-link to="#" class="dropdown-item">Outros Documentos</router-link
          >
        </base-dropdown> -->
      </ul>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item d-lg-block ml-lg-4">
          <a :href="baseURL + baseApiRota + 'login'" class="btn btn-neutral btn-icon">
            <span class="btn-inner--icon">
              <i class="fa fa-sign-in mr-2"></i>
            </span>
            <span class="nav-link-inner--text">Entrar</span>
          </a>
        </li>
        <li class="nav-item d-lg-block ml-lg-4">
          <a :href="baseURL + baseApiRota + 'cadastro'" class="btn btn-info btn-icon">
            <span class="btn-inner--icon">
              <i class="fa fa-user-plus mr-2"></i>
            </span>
            <span class="nav-link-inner--text">Cadastro de Ofertante</span>
          </a>
        </li>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import { baseURL, baseApiRota } from "@/global";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
    baseURL,
    baseApiRota,
  },
  data() {
    return {
      baseURL,
      baseApiRota,
    };
  },
};
</script>
<style>

</style>
