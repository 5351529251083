var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "navbar-nav ml-lg-auto" }, [
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          { staticClass: "nav-link nav-link-icon", attrs: { href: "#" } },
          [
            _c("i", { staticClass: "ni ni-favourite-28" }),
            _c("span", { staticClass: "nav-link-inner--text d-lg-none" }, [
              _vm._v("Discover"),
            ]),
          ]
        ),
      ]),
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          { staticClass: "nav-link nav-link-icon", attrs: { href: "#" } },
          [
            _c("i", { staticClass: "ni ni-notification-70" }),
            _c("span", { staticClass: "nav-link-inner--text d-lg-none" }, [
              _vm._v("Profile"),
            ]),
          ]
        ),
      ]),
      _c("li", { staticClass: "nav-item dropdown" }, [
        _c(
          "a",
          {
            staticClass: "nav-link nav-link-icon",
            attrs: {
              href: "#",
              id: "navbar-default_dropdown_1",
              role: "button",
              "data-toggle": "dropdown",
              "aria-haspopup": "true",
              "aria-expanded": "false",
            },
          },
          [
            _c("i", { staticClass: "ni ni-settings-gear-65" }),
            _c("span", { staticClass: "nav-link-inner--text d-lg-none" }, [
              _vm._v("Settings"),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "dropdown-menu dropdown-menu-right",
            attrs: { "aria-labelledby": "navbar-default_dropdown_1" },
          },
          [
            _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
              _vm._v("Action"),
            ]),
            _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
              _vm._v("Another action"),
            ]),
            _c("div", { staticClass: "dropdown-divider" }),
            _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
              _vm._v("Something else here"),
            ]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }