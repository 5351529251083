var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "row row-grid justify-content-between align-items-center mt-lg",
    },
    [
      _c(
        "div",
        { staticClass: "col-lg-5" },
        [
          _c("h3", { staticClass: "h4 text-success font-weight-bold mb-4" }, [
            _vm._v("Progress bars"),
          ]),
          _c("base-progress", {
            attrs: { value: 40, label: "Task completed" },
          }),
          _c("base-progress", {
            attrs: { type: "primary", value: 60, label: "Task completed" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-lg-5" },
        [
          _c("h3", { staticClass: "h4 text-success font-weight-bold mb-5" }, [
            _vm._v("Pagination"),
          ]),
          _c("base-pagination", {
            attrs: { "page-count": 10 },
            model: {
              value: _vm.pagination.default,
              callback: function ($$v) {
                _vm.$set(_vm.pagination, "default", $$v)
              },
              expression: "pagination.default",
            },
          }),
          _c("base-pagination", {
            attrs: { "page-count": 10 },
            model: {
              value: _vm.pagination.danger,
              callback: function ($$v) {
                _vm.$set(_vm.pagination, "danger", $$v)
              },
              expression: "pagination.danger",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }