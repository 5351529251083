<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4"></div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile" no-body>
          <div class="px-4">
            <div class="row justify-content-start"></div>
            <div class="text-center">
              <div class="container ct-example-row"></div>
            </div>

            <!-- <div v-if="error" class="error">
              {{ errorMessage }}
            </div> -->
            <div class="py-5 text-left" v-if="post">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-12">
                    <h4 class="display-4 text-default">Busca Detalhada:</h4>
                  </div>
                </div>
                <div class="row justify-content-left">
                  <div class="col-lg-3">
                    <base-radio class="mb-3" v-model="radioCategory" name="0">
                      Ofertantes</base-radio
                    >
                  </div>
                  <div class="col-lg-3">
                    <base-radio class="mb-3" v-model="radioCategory" name="4">
                      Grupos Informais</base-radio
                    >
                  </div>
                  <div class="col-lg-3">
                    <base-radio class="mb-3" v-model="radioCategory" name="2">
                      Associações e Cooperativas</base-radio
                    >
                  </div>
                  <div class="col-lg-3">
                    <base-radio class="mb-3" v-model="radioCategory" name="3">
                      Ofertas</base-radio
                    >
                  </div>
                </div>
                <!-- SE OFERTANTES  -->
                <div class="col-lg-12">
                  <!-- Filtrar por Categoria dos Ofertantes  -->
                  <div
                    class="row justify-content-left formMargin"
                    v-show="radioCategory == 0"
                  >
                    <div class="col-lg-12">
                      <h6 class="display-6 text-default">
                        <base-checkbox
                          class="mb-3"
                          v-model="checkEnable.category"
                          name="OFERTANTES"
                          >Filtrar por Categoria dos Ofertantes</base-checkbox
                        >
                      </h6>
                    </div>
                    <b-row
                      class="col-12 mb-3 ml-2"
                      v-show="checkEnable.category"
                    >
                      <div class="col-lg-3">
                        <base-checkbox
                          class="mb-3"
                          v-model="checkProducer.individual"
                          name="INDIVIDUAL"
                          :disabled="!checkEnable.category"
                        >
                          Individuais</base-checkbox
                        >
                      </div>
                      <div class="col-lg-3">
                        <base-checkbox
                          class="mb-3"
                          v-model="checkProducer.informal"
                          name="INFORMAL"
                          :disabled="!checkEnable.category"
                        >
                          Em Grupos informais</base-checkbox
                        >
                      </div>
                      <div class="col-lg-6">
                        <base-checkbox
                          class="mb-3"
                          v-model="checkProducer.association"
                          name="FORMAL"
                          :disabled="!checkEnable.category"
                        >
                          Em Associações e Cooperativas</base-checkbox
                        >
                      </div>
                    </b-row>
                  </div>
                  <!-- filtrar por gênero -->
                  <!-- Ofertantes -->
                  <!-- Grupos Informais -->
                  <!-- Associações e Cooperativas -->

                  <div
                    class="row justify-content-left formMargin"
                    v-show="
                      radioCategory == 0 ||
                      radioCategory == 4 ||
                      radioCategory == 2
                    "
                  >
                    <div class="col-lg-12">
                      <h6 class="display-6 text-default">
                        <base-checkbox
                          class="mb-3"
                          v-model="checkEnable.gender"
                          name="INFORMAL"
                          >Filtrar por Gênero</base-checkbox
                        >
                      </h6>
                    </div>
                    <b-row class="col-12 mb-3 ml-2" v-show="checkEnable.gender">
                      <div class="col-lg-3">
                        <base-checkbox
                          class="mb-3"
                          v-model="checkGender.male"
                          name="M"
                          :disabled="!checkEnable.gender"
                        >
                          Masculino</base-checkbox
                        >
                      </div>
                      <div class="col-lg-3">
                        <base-checkbox
                          class="mb-3"
                          v-model="checkGender.female"
                          name="F"
                          :disabled="!checkEnable.gender"
                        >
                          Feminino</base-checkbox
                        >
                      </div>                     
                    </b-row>
                  </div>

                  <!-- filtrar por idade -->
                  <div
                    class="row justify-content-md-center formMargin"
                    v-show="
                      radioCategory == 0 ||
                      radioCategory == 4 ||
                      radioCategory == 2
                    "
                  >
                    <div class="col-lg-12">
                      <h6 class="display-6 text-default">
                        <base-checkbox
                          class="mb-3"
                          v-model="checkEnable.age"
                          name="INFORMAL"
                          >Filtrar por Idade</base-checkbox
                        >
                      </h6>
                    </div>
                    <b-row class="col-12 mb-3 ml-2" v-show="checkEnable.age">
                      <div class="col-sm-2">
                        De <strong>{{ parseInt(sliderAge[0]) }} </strong> anos
                      </div>
                      <div class="col-sm-8">
                        <base-slider
                          v-model="sliderAge"
                          :range="{ min: 0, max: 100 }"
                          :disabled="!checkEnable.age"
                        >
                        </base-slider>
                      </div>
                      <div class="col-sm-2">
                        Até <strong>{{ parseInt(sliderAge[1]) }}</strong> anos
                      </div>
                    </b-row>
                  </div>

                  <!-- filtrar por financiamento -->
                  <div
                    v-show="
                      radioCategory == 0 ||
                      radioCategory == 4 ||
                      radioCategory == 2
                    "
                  >
                    <b-row>
                      <div class="col-lg-12 d-flex flex-wrap">
                        <h6 class="display-7 text-default">
                          <base-checkbox
                            class="mb-3"
                            v-model="checkEnable.funding"
                            name="INFORMAL"
                            >Filtrar por Financiamento</base-checkbox
                          >
                        </h6>
                      </div>
                    </b-row>
                    <b-row v-show="checkEnable.funding" class="mb-3 ml-1">
                      <div class="col-lg-2">
                        <base-checkbox
                          class="mb-1"
                          v-model="checkFunding.bid"
                          name="2"
                          :disabled="!checkEnable.funding"
                        >
                          BID</base-checkbox
                        >
                      </div>
                      <div class="col-lg-2">
                        <base-checkbox
                          class="mb-1"
                          v-model="checkFunding.bird"
                          name="3"
                          :disabled="!checkEnable.funding"
                        >
                          BIRD</base-checkbox
                        >
                      </div>
                      <div class="col-lg-2">
                        <base-checkbox
                          class="mb-1"
                          v-model="checkFunding.fida"
                          name="3"
                          :disabled="!checkEnable.funding"
                        >
                          FIDA</base-checkbox
                        >
                      </div>
                    </b-row>
                  </div>

                  <!-- Filtrar por Autodeclaração -->
                  <div
                    v-show="
                      radioCategory == 0 ||
                      radioCategory == 4 ||
                      radioCategory == 2
                    "
                  >
                    <b-row>
                      <div class="col-lg-12">
                        <h6 class="display-7 text-default">
                          <base-checkbox
                            class="mb-3"
                            v-model="checkEnable.declaration"
                            name="INFORMAL"
                            >Filtrar por Autodeclaração</base-checkbox
                          >
                        </h6>
                      </div>
                    </b-row>
                    <b-row v-show="checkEnable.declaration">
                      <div class="col-lg-12 d-flex flex-wrap">
                        <div class="col-lg-2">
                          <base-checkbox
                            class="mb-2"
                            v-model="checkDeclaration.indigena"
                            name="0"
                            :disabled="!checkEnable.declaration"
                          >
                            Indigena</base-checkbox
                          >
                        </div>
                        <div class="col-lg-2">
                          <base-checkbox
                            class="mb-2"
                            v-model="checkDeclaration.quilombola"
                            name="4"
                            :disabled="!checkEnable.declaration"
                          >
                            Quilombola</base-checkbox
                          >
                        </div>
                        <div class="col-lg-2">
                          <base-checkbox
                            class="mb-2"
                            v-model="checkDeclaration.cigano"
                            name="4"
                            :disabled="!checkEnable.declaration"
                          >
                            Cigano</base-checkbox
                          >
                        </div>
                        <div class="col-lg-2">
                          <base-checkbox
                            class="mb-2"
                            v-model="checkDeclaration.povos_de_terreiro"
                            name="4"
                            :disabled="!checkEnable.declaration"
                          >
                            Povos de Terreiro</base-checkbox
                          >
                        </div>
                        <div class="col-lg-2">
                          <base-checkbox
                            class="mb-2"
                            v-model="checkDeclaration.pescador_artesanal"
                            name="4"
                            :disabled="!checkEnable.declaration"
                          >
                            Pescador Artesanal</base-checkbox
                          >
                        </div>
                        <div class="col-lg-2">
                          <base-checkbox
                            class="mb-2"
                            v-model="checkDeclaration.outro"
                            name="4"
                            :disabled="!checkEnable.declaration"
                          >
                            Outro</base-checkbox
                          >
                        </div>
                      </div>
                    </b-row>
                  </div>
                </div>

                <!-- SE OFERTAS  -->
                <div class="col-lg-12" v-if="radioCategory == 3">
                  <div class="row justify-content-left formMargin">
                    <div class="col-lg-12">
                      <h6 class="display-6 text-default">
                        Filtrar por Produto
                      </h6>
                    </div>
                    <div class="col-lg-3">
                      <base-radio class="mb-3" v-model="radioProduct" name="0">
                        Todos os produtos</base-radio
                      >
                    </div>
                    <div class="col-lg-12">
                      <base-radio class="mb-3" v-model="radioProduct" name="1">
                        Um produto específico:</base-radio
                      >
                    </div>
                    <div class="col-lg-12">
                      <select
                        v-model="selected"
                        label="Selecione o produto:"
                        class="selectProduct"
                        :disabled="checkEnable.allProduct"
                      >
                        <option
                          v-for="product in products"
                          v-bind:value="product.value"
                          v-bind:key="product.value"
                        >
                          {{ product.label }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="row justify-content-left formMargin">
                    <div class="col-lg-12">
                      <h6 class="display-12 text-default">
                        <base-checkbox
                          class="mb-3"
                          v-model="checkEnable.month"
                          name="INFORMAL"
                          >Filtrar por Mês de produção</base-checkbox
                        >
                      </h6>
                    </div>
                    <b-row v-show="checkEnable.month">
                      <div class="col-lg-1">
                        <base-checkbox
                          class="mb-2 ml-3"
                          v-model="checkProductMonth[0][1]"
                          name="0"
                          :disabled="!checkEnable.month"
                        >
                          Jan</base-checkbox
                        >
                      </div>
                      <div class="col-lg-1">
                        <base-checkbox
                          class="mb-2 ml-3"
                          v-model="checkProductMonth[1][1]"
                          name="0"
                          :disabled="!checkEnable.month"
                        >
                          Fev</base-checkbox
                        >
                      </div>
                      <div class="col-lg-1">
                        <base-checkbox
                          class="mb-2 ml-3"
                          v-model="checkProductMonth[2][1]"
                          name="4"
                          :disabled="!checkEnable.month"
                        >
                          Mar</base-checkbox
                        >
                      </div>
                      <div class="col-lg-1">
                        <base-checkbox
                          class="mb-1 ml-3"
                          v-model="checkProductMonth[3][1]"
                          name="3"
                          :disabled="!checkEnable.month"
                        >
                          Abr</base-checkbox
                        >
                      </div>
                      <div class="col-lg-1">
                        <base-checkbox
                          class="mb-1 ml-3"
                          v-model="checkProductMonth[4][1]"
                          name="2"
                          :disabled="!checkEnable.month"
                        >
                          Mai</base-checkbox
                        >
                      </div>
                      <div class="col-lg-1">
                        <base-checkbox
                          class="mb-1 ml-3"
                          v-model="checkProductMonth[5][1]"
                          name="3"
                          :disabled="!checkEnable.month"
                        >
                          Jun</base-checkbox
                        >
                      </div>
                      <div class="col-lg-1">
                        <base-checkbox
                          class="mb-1 ml-3"
                          v-model="checkProductMonth[6][1]"
                          name="3"
                          :disabled="!checkEnable.month"
                        >
                          Jul</base-checkbox
                        >
                      </div>
                      <div class="col-lg-1">
                        <base-checkbox
                          class="mb-1 ml-3" 
                          v-model="checkProductMonth[7][1]"
                          name="3"
                          :disabled="!checkEnable.month"
                        >
                          Ago</base-checkbox
                        >
                      </div>
                      <div class="col-lg-1">
                        <base-checkbox
                          class="mb-1 ml-3"
                          v-model="checkProductMonth[8][1]"
                          name="3"
                          :disabled="!checkEnable.month"
                        >
                          Set</base-checkbox
                        >
                      </div>
                      <div class="col-lg-1">
                        <base-checkbox
                          class="mb-1 ml-3"
                          v-model="checkProductMonth[9][1]"
                          name="3"
                          :disabled="!checkEnable.month"
                        >
                          Out</base-checkbox
                        >
                      </div>
                      <div class="col-lg-1">
                        <base-checkbox
                          class="mb-1 ml-3"
                          v-model="checkProductMonth[10][1]"
                          name="3"
                          :disabled="!checkEnable.month"
                        >
                          Nov</base-checkbox
                        >
                      </div>
                      <div class="col-lg-1">
                        <base-checkbox
                          class="mb-1 ml-3"
                          v-model="checkProductMonth[11][1]"
                          name="3"
                          :disabled="!checkEnable.month"
                        >
                          Dec</base-checkbox
                        >
                      </div>
                    </b-row>
                  </div>
                </div>

                <!-- SE ESTADOS  -->
                <div class="row justify-content-left formMargin">
                  <div class="col-lg-12">
                    <h6 class="display-6 text-default">
                      <base-radio
                        class="mb-3"
                        v-model="radioEnableState"
                        name="1"
                        >Exibir para Todos os Estados</base-radio
                      >
                    </h6>
                  </div>
                  <div class="col-lg-3">
                    <base-radio class="mb-3" v-model="radioEnableState" name="">
                      Filtrar por Estado:</base-radio
                    >
                  </div>
                  <b-row v-show="!radioEnableState" class="mb-3 ml-2">
                    <div class="col-lg-1">
                      <base-radio
                        class="mb-3"
                        v-model="radioState"
                        name="6"
                        :disabled="!!radioEnableState"
                        ><img
                          src="../../public/img/icons/flags/al.png"
                          width="23px"
                          height="17px"
                        />
                        AL</base-radio
                      >
                    </div>
                    <div class="col-lg-1">
                      <base-radio
                        class="mb-3"
                        v-model="radioState"
                        name="7"
                        :disabled="!!radioEnableState"
                        ><img
                          src="../../public/img/icons/flags/ba.png"
                          width="23px"
                          height="17px"
                        />
                        BA</base-radio
                      >
                    </div>
                    <div class="col-lg-1">
                      <base-radio
                        class="mb-3"
                        v-model="radioState"
                        name="2"
                        :disabled="!!radioEnableState"
                        ><img
                          src="../../public/img/icons/flags/ce.png"
                          width="23px"
                          height="17px"
                        />
                        CE</base-radio
                      >
                    </div>
                    <div class="col-lg-1">
                      <base-radio
                        class="mb-3"
                        v-model="radioState"
                        name="1"
                        :disabled="!!radioEnableState"
                        ><img
                          src="../../public/img/icons/flags/ma.png"
                          width="23px"
                          height="17px"
                        />
                        MA</base-radio
                      >
                    </div>
                    <div class="col-lg-1">
                      <base-radio
                        class="mb-3"
                        v-model="radioState"
                        name="8"
                        :disabled="!!radioEnableState"
                        ><img
                          src="../../public/img/icons/flags/pb.png"
                          width="23px"
                          height="17px"
                        />
                        PB</base-radio
                      >
                    </div>
                    <div class="col-lg-1">
                      <base-radio
                        class="mb-3"
                        v-model="radioState"
                        name="5"
                        :disabled="!!radioEnableState"
                        ><img
                          src="../../public/img/icons/flags/pe.png"
                          width="23px"
                          height="17px"
                        />
                        PE</base-radio
                      >
                    </div>
                    <div class="col-lg-1">
                      <base-radio
                        class="mb-3"
                        v-model="radioState"
                        name="3"
                        :disabled="!!radioEnableState"
                        ><img
                          src="../../public/img/icons/flags/pi.png"
                          width="23px"
                          height="17px"
                        />
                        PI</base-radio
                      >
                    </div>
                    <div class="col-lg-1">
                      <base-radio
                        class="mb-3"
                        v-model="radioState"
                        name="4"
                        :disabled="!!radioEnableState"
                        ><img
                          src="../../public/img/icons/flags/rn.png"
                          width="23px"
                          height="17px"
                        />
                        RN</base-radio
                      >
                    </div>
                    <div class="col-lg-1">
                      <base-radio
                        class="mb-3"
                        v-model="radioState"
                        name="9"
                        :disabled="!!radioEnableState"
                        ><img
                          src="../../public/img/icons/flags/se.png"
                          width="23px"
                          height="17px"
                        />
                        SE</base-radio
                      >
                    </div>
                  </b-row>
                </div>

                <!-- BOTÃO VER NO MAPA  -->
                <div class="col-lg-12 formMargin">
                  <base-button
                    type="primary"
                    icon="ni ni-world"
                    v-on:click="loadData(true)"
                    >Clique para buscar</base-button
                  >
                </div>
                <!-- MAPA E TABELA  -->
                <div class="row justify-content-left">
                  <div class="col-lg-12">
                    <div style="width: 100%" id="container">
                      <chart
                        :mapAddress="mapAddress"
                        :data="data"
                        :category="cat"
                        :init="init"
                        :end="end"
                      ></chart>
                    </div>
                    <div>
                      <b-table
                        class="tableData"
                        striped
                        hover
                        responsive
                        :fields="fields"
                        :items="results"
                      >
                      </b-table>
                    </div>
                    <div class="col-lg-12 formMargin">
                      <base-button
                        type="success"
                        icon="ni ni-cloud-download-95"
                        v-on:click="downloadData(downloadLink)"                        
                        >Fazer Download dos Dados em Formato de
                        Planilha</base-button
                      >                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- NOTA  -->
            <div>
              <span
                ><p class="small">
                  *{{ cat }} cujo o endereço do cadastro ocorre em outro estado
                  fora da região especificada.
                </p></span
              >
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
import { baseApiUrl, baseURL } from "@/global";
import axios from "axios";

import Chart from "@/views/components/Chart";
import loadpage from "./components/loadPage.vue";
import Cities from "@/views/components/maps/cities";

export default {
  name: "vitrine",
  components: {
    chart: Chart,
    loadpage,
  },

  data() {
    return {
      baseURL,
      radioState: "0",
      radioCategory: "0",
      radioProduct: "0",
      radioEnableState: "1",
      checkEnable: {
        category: false,
        gender: false,
        age: false,
        declaration: false,
        funding: false,
        month: false,
        allProduct: true,
        etnia: false,
      },
      // declarationFormal: false,
      // fundingFormal: false,
      // declarationInformal: false,
      // fundingInformal: false,
      checkGender: {
        male: false,
        female: false
      },
      checkDeclaration: {
        indigena: false,
        quilombola: false,
        cigano: false,
        povos_de_terreiro: false,
        pescador_artesanal: false,
        outro: false,
      },
      checkFunding: {
        bid: false,
        bird: false,
        fida: false,
        // wo: false,
      },
      checkEtnia: {
        branca: false,
        preta: false,
        parda: false,
        amarela: false,
        indigena: false,
      },
      checkProducer: {
        individual: false,
        informal: false,
        association: false,
        //cooperative: true,
      },
      checkProductMonth: [
        ["january", false],
        ["february", false],
        ["march", false],
        ["april", false],
        ["may", false],
        ["june", false],
        ["july", false],
        ["august", false],
        ["september", false],
        ["october", false],
        ["november", false],
        ["december", false],
      ],

      sliderAge: [18, 80],
      loading: true,
      post: true,
      error: false,
      mapAddress: 2,
      data: {},
      cities: Cities,
      ufStates: {
        0: {
          id: "0",
          ibgeId: 2,
          name: "Todos",
          acronym: "br",
        },
        6: {
          id: "6",
          ibgeId: 27,
          name: "Alagoas",
          acronym: "al",
        },
        7: {
          id: "7",
          ibgeId: 29,
          name: "Bahia",
          acronym: "ba",
        },
        2: {
          id: "2",
          ibgeId: 23,
          name: "Ceará",
          acronym: "ce",
        },
        1: {
          id: "1",
          ibgeId: 21,
          name: "Maranhão",
          acronym: "ma",
        },
        8: {
          id: "8",
          ibgeId: 25,
          name: "Paraíba",
          acronym: "pb",
        },
        5: {
          id: 5,
          ibgeId: 26,
          name: "Pernambuco",
          acronym: "pe",
        },
        3: {
          id: "3",
          ibgeId: 22,
          name: "Piauí",
          acronym: "pi",
        },
        4: {
          id: "4",
          ibgeId: 24,
          name: "Rio Grande do Norte",
          acronym: "rn",
        },
        9: {
          id: "9",
          ibgeId: 28,
          name: "Sergipe",
          acronym: "se",
        },
      },
      fields: [
        {
          key: "region",
          label: "Localidade",
          sortable: true,
        },
        {
          key: "quantity",
          label: "Quantidade de Ofertantes",
          sortable: true,
        },
      ],
      results: [],
      alreadyLoadProduct: false,
      selected: 0,
      //options: [{ text: "--- Selecione o produto ---", value: 0 }],
      products: [{ label: "--- Selecione o produto ---", value: 0 }],
      product: null,
      downloadLink: "",
      cat: "Ofertantes",
      init: 0,
      end: 0,
    };
  },
  watch: {
    selectedLocation: function () {
      //console.log(this.selectedLocation);
      for (let i in this.ufStates) {
        if (this.ufStates[i].acronym == this.selectedLocation) {
          this.radioState = this.ufStates[i].id;
        }
      }
    },
    radioProduct: function () {
      //console.log(this.radioProduct)
      if (this.radioProduct == "0") {
        this.checkEnable.allProduct = true;
      } else {
        this.checkEnable.allProduct = false;
        this.valProduct2 = false;
        if (!this.alreadyLoadProduct) {
          this.alreadyLoadProduct = true;
          this.loadProducts();
        }
      }
    },
    radioReport: function () {
      if (this.radioReport == "offers") {
        this.valProduct2 = false;
        if (this.products == null) {
          this.loadProducts();
        }
      }
    },
    product: function () {
      if (this.product > 0) {
        this.valProduct = true;
      } else {
        this.valProduct = false;
      }
    },
  },
  methods: {
    loadData() {
      this.init++;
      if (this.radioCategory == "0") {
        this.cat = "Ofertantes";
        this.fields[1].label = "Quantidade de Ofertantes";
        this.loadDataFromProducers();
      } else if (this.radioCategory == "2") {
        this.cat = "Associações e Cooperativas";
        this.fields[1].label = "Quantidade de Associações e Cooperativas";
        // this.loadDataFromFormal();
        this.loadDataFromGroups();
      } else if (this.radioCategory == "3") {
        this.cat = "Ofertas";
        this.fields[1].label = "Quantidade de Ofertas";
        this.loadDataFromProducts();
      } else if (this.radioCategory == "4") {
        this.cat = "Grupos Informais";
        this.fields[1].label = "Quantidade de Grupos Informais";
        // this.loadDataFromInformal();
        this.loadDataFromGroups();
      } else {
        console.log("erro");
      }
    },

    loadDataFromGroups() {
      let mapId = 2;
      let region = "";
      
      let fBid = "";
      let fBird = "";
      let fFida = "";
      
      let genderM = "",
        genderF = "";
      let age = "";
      let autodeclaration = "";

      let x = {};

      if (this.radioEnableState == "") {
        mapId = this.ufStates[this.radioState].ibgeId;
        region = "region=" + this.ufStates[this.radioState].ibgeId + "&";
      }

       if (this.checkEnable.gender) {
        if (this.checkGender.male) {
          genderM = "gender=M&";
        }
        if (this.checkGender.female) {
          genderF = "gender=F&";
        }

      } else {
        genderM = "";
        genderF = "";
      }

      if (this.checkEnable.age) {
        const ageI = parseInt(this.sliderAge[0]);
        const ageF = parseInt(this.sliderAge[1]);
        age = "initial_age=" + ageI + "&" + "final_age=" + ageF + "&";
      } else {
        age = "";
      }

      if (this.checkEnable.declaration) {
        autodeclaration = "";

        if (this.checkDeclaration.indigena) {
          autodeclaration += "self_declaration=indigena&";
        }
        if (this.checkDeclaration.quilombola) {
          autodeclaration += "self_declaration=quilombola&";
        }
        if (this.checkDeclaration.cigano) {
          autodeclaration += "self_declaration=cigano&";
        }
        if (this.checkDeclaration.povos_de_terreiro) {
          autodeclaration += "self_declaration=povos_de_terreiro&";
        }
        if (this.checkDeclaration.pescador_artesanal) {
          autodeclaration += "self_declaration=pescador_artesanal&";
        }
        if (this.checkDeclaration.outro) {
          autodeclaration += "self_declaration=outro&";
        }
      }

      if (this.checkEnable.funding) {
        fBid =
          "funding_bid=" + this.convertBoolean(this.checkFunding.bid) + "&";
        fBird =
          "funding_bird=" + this.convertBoolean(this.checkFunding.bird) + "&";
        fFida =
          "funding_fida=" + this.convertBoolean(this.checkFunding.fida) + "&";
      }
      let group_type = "";

      if (this.radioCategory == 4) group_type = "natural_group";
      else if (this.radioCategory == 2) group_type = "legal_person";

      let query =
        baseApiUrl +
        "/opendata/indicators?" +
        "category=" +
        group_type +
        "&" +
        genderM+
        genderF+
        age+
        region +
        fBid +
        fBird +
        fFida +
        autodeclaration;
      this.downloadLink = query + "fmt=xls";
      query = query + "fmt=json";
      console.log("URL da requisição: " + query);

      axios
        .get(query)
        .then((response) => {
          this.data = Object.assign({}, {});
          if (region == "") {
            x = response.data.sirafne;
          } else {
            x = response.data.sirafne[mapId];
          }

          this.data = Object.assign({}, x);
          //console.log(this.data);
          this.results = [];
          let sum = 0;
          for (let key in this.data) {
            sum += this.data[key];
            this.results.push({
              region: this.getRegionName(key),
              quantity: this.formatNumber(this.data[key]),
            });
          }
          this.results.push({
            region: "Outros*",
            quantity: this.formatNumber(response.data["others"]),
          });
          sum += response.data["others"];
          this.results.push({
            region: "Total",
            quantity: this.formatNumber(sum),
          });
          //this.mapAddress = 0;
          this.mapAddress = mapId;
          this.end++;
        })
        .catch((error) => {
          console.log(error);
          this.post = false;
          this.error = true;
        });
    },

    loadDataFromProducts() {
      let region = "";
      let month = "";
      let mapId = 2;
      let product = "";
      if (this.checkEnable.month) {
        for (let i in this.checkProductMonth) {
          if (this.checkProductMonth[i][1]) {
            month = month + "month=" + this.checkProductMonth[i][0] + "&";
          }
        }
      } else {
        for (let i in this.checkProductMonth) {
          month = month + "month=" + this.checkProductMonth[i][0] + "&";
        }
      }

      if (this.radioEnableState == "") {
        region = "region=" + this.ufStates[this.radioState].ibgeId + "&";
        mapId = this.ufStates[this.radioState].ibgeId;
      }

      if (this.radioProduct == "1") {
        product = "product=" + this.selected + "&";
      }

      let query =
        baseApiUrl +
        "/opendata/indicators?" +
        "category=offer&" +
        region +
        month +
        product;
      this.downloadLink = query + "fmt=xls";
      query = query + "fmt=json";

      console.log("URL da requisição: " + query);
      axios
        .get(query)
        .then((response) => {
          this.data = Object.assign({}, {});
          let x = {};
          if (region == "") {
            x = response.data["sirafne"];
            for (let i in x) {
              Object.assign(this.data, { [i]: x[i].count });
            }
            //console.log(x);
            /* if (product == "") {
              for (let i in x) {
                Object.assign(this.data, { [i]: x[i].count });
              }
            } else {
              for (let i in x) {
                x[i].total = 0;
                for (let j in x[i]) {
                  x[i].total += x[i][j];
                }
                x[i].total = x[i].total / 2;
                Object.assign(this.data, { [i]: x[i].total });
              }
            } */
          } else {
            x = response.data["sirafne"][mapId];
            for (let i in x) {
              Object.assign(this.data, { [i]: x[i].count });
            }
            /* if (product == "") {
              for (let i in x) {
                Object.assign(this.data, { [i]: x[i].count });
              }
            } else {
              //console.log(x);
              for (let i in x) {
                x[i].total = 0;
                for (let j in x[i]) {
                  x[i].total += x[i][j];
                }
                x[i].total = x[i].total / 2;
                Object.assign(this.data, { [i]: x[i].total });
              }
            } */
          }

          //this.data = Object.assign({}, x);
          //console.log(this.data);
          this.results = [];
          let sumOffers = 0;
          let sumProducts = 0;
          if (product != "") {
            this.fields[1].label =
              "Ofertas de " +
              response.data["product"]["name"] +
              " em " +
              response.data["product"]["unit_of_measurement"];
            for (let i in x) {
              sumOffers += x[i].count;
              sumProducts += x[i].annual_offer;
              this.results.push({
                region: this.getRegionName(i),
                quantity:
                  this.formatNumber(x[i].annual_offer) +
                  " " +
                  response.data["product"]["unit_of_measurement"] +
                  " em " +
                  this.formatNumber(x[i].count) +
                  " ofertas",
              });
              //console.log(new Intl.NumberFormat('pt-BR', { style: 'decimal' }).format(parseInt(x[i].annual_offer)));
            }
            this.results.push({
              region: "Outros*",
              quantity:
                this.formatNumber(response.data["others"]["quantity"]) +
                " " +
                response.data["product"]["unit_of_measurement"] +
                " em " +
                this.formatNumber(response.data["others"]["count"]) +
                " ofertas",
            });
            sumOffers += response.data["others"]["count"];
            sumProducts += response.data["others"]["quantity"];
            this.results.push({
              region: "Total",
              quantity:
                this.formatNumber(sumProducts) +
                " " +
                response.data["product"]["unit_of_measurement"] +
                " em " +
                this.formatNumber(sumOffers) +
                " ofertas",
            });
          } else {
            for (let key in this.data) {
              sumOffers += this.data[key];
              this.results.push({
                region: this.getRegionName(key),
                quantity: this.formatNumber(this.data[key]),
              });
            }
            this.results.push({
              region: "Outros*",
              quantity: this.formatNumber(response.data["others"]["count"]),
            });
            sumOffers += response.data["others"]["count"];
            this.results.push({
              region: "Total",
              quantity: this.formatNumber(sumOffers),
            });
          }
          //this.mapAddress = 0;
          this.mapAddress = mapId;
          this.end++;
        })
        .catch((error) => {
          console.log(error);
          this.post = false;
          this.error = true;
        });
    },

    loadDataFromProducers() {
      let mapId = 2;
      let region = "";
      let tFormal = "",
        tInformal = "",
        tIndividual = "";
      let genderM = "",
        genderF = "";
      let age = "";
      let autodeclaration = "";

      let fBid = "";
      let fBird = "";
      let fFida = "";

      let x = {};

      if (this.radioEnableState == "") {
        mapId = this.ufStates[this.radioState].ibgeId;
        region = "region=" + this.ufStates[this.radioState].ibgeId + "&";
      }

      if (this.checkEnable.category) {
        if (this.checkProducer.individual) {
          tIndividual = "type=INDIVIDUAL&";
        }
        if (this.checkProducer.informal) {
          tInformal = "type=INFORMAL&";
        }
        if (this.checkProducer.association) {
          tFormal = "type=FORMAL&";
        }
      } else {
        tIndividual = "type=INDIVIDUAL&";
        tInformal = "type=INFORMAL&";
        tFormal = "type=FORMAL&";
      }

      if (this.checkEnable.gender) {
        if (this.checkGender.male) {
          genderM = "gender=M&";
        }
        if (this.checkGender.female) {
          genderF = "gender=F&";
        }
       
      } else {
        genderM = "";
        genderF = "";      
      }

      if (this.checkEnable.age) {
        const ageI = parseInt(this.sliderAge[0]);
        const ageF = parseInt(this.sliderAge[1]);
        age = "initial_age=" + ageI + "&" + "final_age=" + ageF + "&";
      } else {
        age = "";
      }

      if (this.checkEnable.declaration) {
        autodeclaration = "";

        if (this.checkDeclaration.indigena) {
          autodeclaration += "self_declaration=indigena&";
        }
        if (this.checkDeclaration.quilombola) {
          autodeclaration += "self_declaration=quilombola&";
        }
        if (this.checkDeclaration.cigano) {
          autodeclaration += "self_declaration=cigano&";
        }
        if (this.checkDeclaration.povos_de_terreiro) {
          autodeclaration += "self_declaration=povos_de_terreiro&";
        }
        if (this.checkDeclaration.pescador_artesanal) {
          autodeclaration += "self_declaration=pescador_artesanal&";
        }
        if (this.checkDeclaration.outro) {
          autodeclaration += "self_declaration=outro&";
        }
        console.log("Declaration" + autodeclaration);
      }

      if (this.checkEnable.funding) {
        fBid =
          "funding_bid=" + this.convertBoolean(this.checkFunding.bid) + "&";
        fBird =
          "funding_bird=" + this.convertBoolean(this.checkFunding.bird) + "&";
        fFida =
          "funding_fida=" + this.convertBoolean(this.checkFunding.fida) + "&";
      }
      let query =
        baseApiUrl +
        "/opendata/indicators?" +
        "category=producer&" +
        region +
        tIndividual +
        tInformal +
        tFormal +
        genderM +
        genderF +     
        age +
        autodeclaration +
        fBid +
        fBird +
        fFida;

      this.downloadLink = query + "fmt=xls";
      query = query + "fmt=json";

      console.log("URL da requisição: " + query);

      axios
        .get(query)
        .then((response) => {
          this.data = Object.assign({}, {});
          if (region == "") {
            x = response.data.sirafne;
          } else {
            x = response.data.sirafne[mapId];
          }

          this.data = Object.assign({}, x);
          //console.log(this.data);
          this.results = [];
          let sum = 0;
          for (let key in this.data) {
            sum += this.data[key];
            this.results.push({
              region: this.getRegionName(key),
              quantity: this.formatNumber(this.data[key]),
            });
          }
          sum += response.data["others"];
          this.results.push({
            region: "Outros*",
            quantity: this.formatNumber(response.data["others"]),
          });
          //sum += response.data["others"];
          this.results.push({
            region: "Total",
            quantity: this.formatNumber(sum),
          });
          //console.log(this.results);
          //this.mapAddress = 0;
          this.mapAddress = mapId;
          this.end++;
        })
        .catch((error) => {
          console.log(error);
          this.post = false;
          this.error = true;
        });
    },

    loadProducts() {
      this.smallLoad = true;
      this.valProduct = false;
      //console.log("carregar produtos");
      const url = baseApiUrl + "/opendata/products";

      //console.log(url);
      const response = axios.get(url);
      response.then((resp) => {
        this.products = [];
        //console.log(resp.data);
        const array = resp.data;
        let a = [];
        a.push({
          value: 0,
          label: "--- Selecione o produto ---",
        });
        array.forEach((element) => {
          a.push({
            value: element.id,
            label: element.name + " - " + element.unit_of_measurement,
          });
        });
        this.products = a;
      });
      response.catch((err) => {
        this.products = [];
        console.log(err);
      });
    },

    downloadData(url) {
      const options = {
        method: "get",
        url: url,
        headers: {},
        responseType: "blob",
        //data: {},
      };

      const response = axios(options);
      response.then((resp) => {
        const fileurl = URL.createObjectURL(
          new Blob([resp.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = fileurl;
        //console.log(link);
        link.setAttribute("download", "relatorio.xls");
        document.body.appendChild(link);
        link.click();
        this.smallLoad = false;
        this.downloading = false;
        this.valReport = true;
      });
      response.catch((err) => {
        console.log("erro na requisição");
        console.log(err);
      });
    },

    convertBoolean(val) {
      if (val) {
        return "T";
      } else {
        return "F";
      }
    },

    formatNumber(val) {
      return new Intl.NumberFormat("pt-BR", { style: "decimal" }).format(
        parseInt(val)
      );
    },

    getRegionName(regionID) {
      let regionName = "";
      if (regionID == 21) {
        regionName = "Maranhão";
      } else if (regionID == 22) {
        regionName = "Piauí";
      } else if (regionID == 23) {
        regionName = "Ceará";
      } else if (regionID == 24) {
        regionName = "Rio Grande do Norte";
      } else if (regionID == 25) {
        regionName = "Paraíba";
      } else if (regionID == 26) {
        regionName = "Pernambuco";
      } else if (regionID == 27) {
        regionName = "Alagoas";
      } else if (regionID == 28) {
        regionName = "Sergipe";
      } else if (regionID == 29) {
        regionName = "Bahia";
      } else {
        let s = true;
        let key = 0;
        while (s) {
          if (this.cities[key].pk == regionID) {
            regionName = this.cities[key].fields.name;
            s = false;
          }
          key++;
        }
      }
      return regionName;
    },

    getIndicadores() {
      //this.loading = true;
      let query = baseApiUrl + "/opendata/indicators";
      this.downloadLink = query + "?fmt=xls";
      console.log("URL da requisição: " + query);
      axios
        .get(query)
        .then((response) => {
          this.data = Object.assign({}, response.data.farmers_by_states_count);
          this.mapAddress = 2;

          //console.log(this.data);
          let i = 0;
          this.results = [];
          let sum = 0;
          for (let key in this.data) {
            sum += this.data[key];
            this.results.push({
              region: this.getRegionName(key),
              quantity: this.formatNumber(this.data[key]),
            });
          }
          this.results.push({
            region: "Total",
            quantity: this.formatNumber(sum),
          });
          this.end++;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error = true;
        });
    },
  },
  created() {
    /* console.log("INSTÂNCIA: " + process.env.VUE_APP_TITLE);
        console.log("NODE_ENV: " + process.env.NODE_ENV);
        console.log("BASE_API_URL: " + process.env.VUE_APP_BASE_API_URL);
        console.log("BASE_URL: " + process.env.VUE_APP_BASE_URL); */
    this.mapAddress = 24;
    //console.log(this.mapAddress);
    this.getIndicadores();
    this.radioState = String(
      Math.floor(Math.random() * (Math.floor(9) - Math.ceil(1) + 1)) +
        Math.ceil(1)
    );
  },
};
</script>


<style>
.siraf_stats {
  font-size: 2rem;
  margin-bottom: 0;
}
#map {
  height: 180px;
}

.statCard h6 {
  font-size: 0.8rem;
}

.formMargin {
  margin-top: 20px;
  margin-bottom: 10px;
}
.selectProduct {
  font-size: 0.875rem;
  color: #525f7f;
  border-color: rgba(118, 118, 118, 0.3) !important;
}
</style>
