<template>
  <footer class="footer has-cards">
    <section class="section section-sm">
      <div class="container">
        <div class="row row-grid justify-content-center">
          <div class="col-lg-12 text-center">
            <p class="lead">
              O Sistema de Informação Regional da Agricultura Familiar no
              Nordeste é resultado da parceria entre:
            </p>
            <div class="container">
              <div class="row justify-content-md-between">

                <div class="col-lg-12">
                  <div class="row row-grid">
                    <div class="col-lg-3">
                      <a href="https://funarbe.org.br/" target="_blank" data-toggle="tooltip"
                        data-original-title="Fundação Arthur Bernardes">
                        <img
                          alt="Logo da Fundação Arthur Bernardes. Fonte na cor azul. Sigla FUNARB em caixa alta na linha de cima 
                          e envolvido por duas barras horizontais e a baixo segue o texto 'Fundação Arthur Bernardes' com uma fonte menor.  "
                          v-lazy="'img/brand/others/funarbe.png'" class="img-fluid" />
                      </a>
                    </div>
                    <div class="col-lg-3">
                      <a href="https://www.ufv.br/" target="_blank" data-toggle="tooltip"
                        data-original-title="UFV - Universidade Federal de Viçosa">
                        <img alt="Logo da UFV. É divido em 3 linhas. Na primeira tem a sigla 'UFV' em cor preto. Abaixo tem o texto 
                          'Universidade Federal de Viçosa' e mais abaixo três barras horizontais lado a lado separadas por um espaço. 
                          Suas cores são preto, vermelho e amarelo." v-lazy="'img/brand/others/ufv.png'"
                          class="img-fluid" />
                      </a>
                    </div>
                    <div class="col-lg-3">
                      <a href="https://www.ippds.ufv.br/" target="_blank" data-toggle="tooltip"
                        data-original-title="IPPDS - Instituto de Políticas Públicas e Desenvolvimento Sustentável">
                        <img
                          alt="Logo da IPPDS. É dividido em 4 linhas. Na primeira, uma imagem de um galho com folhas circundando 
                          três bonequinhos com seus braços erguidos, tudo na cor verde com um gradiente.
                           Os bonecos estão se projetando a partir de duas folhas pequenas. Na linha dois, tem a sigla 'IPPDS'. 
                           Na linha três, o texto 'Instituto de Políticas Públicas e'. Na quarta linha o texto 'Desenvolvimento Sustentável' em uma fonte menor."
                          v-lazy="'img/brand/others/ippds.png'" class="img-fluid" />
                      </a>
                    </div>
                    <div class="col-lg-3">
                      <a href="https://aksaam.ufv.br/" data-toggle="tooltip"
                        data-original-title="AKSAAM - Adaptando Conhecimento para a Agricultura Sustentável e o Acesso a Mercados">
                        <img
                          alt="Logo AKSAAM. Dividido em quatro linhas. Na primeira, a sigla 'AKSAAM' na cor marron claro. 
                          Na segunda, uma linha espessa verde com uma folha de árvore no final. Na terceira, o texto 'Adaptando Conhecimento para a' na cor marron. 
                          E na última, o texto 'Agricultura Sustentável e o Acesso a Mercados' em fonte menor e na cor marrom"
                          v-lazy="'img/brand/others/aksaam.png'" class="img-fluid" />
                      </a>
                    </div>

                    <div class="col-lg-3">
                      <a href="https://uern.br" target="_blank" data-toggle="tooltip"
                        data-original-title="UERN - Universidade do Estado do Rio Grande do Norte">
                        <img alt="Logo UERN. Sigla 'UERN' na cor azul escuro." v-lazy="'img/brand/others/uern.png'"
                          class="img-fluid" />
                      </a>
                    </div>
                    <div class="col-lg-3">
                      <a href="http://www.consorcionordeste-ne.com.br/" target="_blank" data-toggle="tooltip"
                        data-original-title="Consórcio Nordeste: O Brasil que cresce unido.">
                        <img alt="Logo dividida em três linhas. Na primeira, o texto 'CONSÓRCIO'. Na segunda, o texto 'NORDESTE', 
                        este tem o primeiro 'E' formado pela base de uma imagem que remete ao mapa de barras coloridas (Gradiente que vai do vermelho, amarelo ao verde escuro) da região nordeste.
                         E na última linha, o texto 'O BRASIL QUE CRESCE UNIDO'. "
                          v-lazy="'img/brand/others/c_nordeste.png'" class="img-fluid" />
                      </a>
                    </div>
                    <div class="col-lg-3">
                      <a href="http://www.rn.gov.br/" target="_blank" data-toggle="tooltip"
                        data-original-title="Secretaria de Estado do Desenvolvimento Rural e da Agricultura Familiar">
                        <img
                          alt="Logo do governo do RN. Formado pelo brazão do RN a esquerda. Na direita, o texto 'RIO GRANDE DO NORTE' ocupando duas linhas. 
                        Abaixo, tem quatro pequenas barras horizontais coloridas. Na outra linha o texto 'GOVERNO DO ESTADO, em um fonte menor e  mais abaixo, o texto 
                        'Secretaria de Estado do Desenvolvimento Rural e da Agricultura Familiar' em caixa alta e uma fonte menor. "
                          v-lazy="'img/brand/others/sedraf.png'" class="img-fluid" />
                      </a>
                    </div>
                    <div class="col-lg-3">
                      <a href="https://www.fida.org.br/" target="_blank" data-toggle="tooltip"
                        data-original-title="FIDA - Fundo Internacional de Desenvolvimento Agrícola">
                        <img alt=" Logo do FIDA. Possuí uma imagem cinza do TRIGO a esquerda. Ao lado a sigla em preto do FIDA. E abaixo de todos, o texto 'Investindo nas populações rurais', na cor preto."
                         v-lazy="'img/brand/others/fida.png'" class="img-fluid" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>

    <div class="container">
      <hr />
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-6">
          <div class="copyright">
            &copy; {{ year }}
            SIRAF-NE
          </div>
        </div>
        <div class="col-md-6">
          <ul class="nav nav-footer justify-content-end">
            <li class="nav-item">
              <!-- <a href="/contatos" class="nav-link"></a> -->
              <router-link to="/contatos" class="nav-link" role="button">Contatos</router-link>              
            </li>
            <li class="nav-item">
              <!-- <a href="/sobre" class="nav-link"></a> -->
              <router-link to="#" class="nav-link" role="button">Sobre</router-link>              
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style>

</style>
