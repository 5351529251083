var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profile-page" }, [
    _vm._m(0),
    _c("section", { staticClass: "section section-skew" }, [
      _c(
        "div",
        { staticClass: "container col-9" },
        [
          _c(
            "card",
            {
              staticClass: "card-profile",
              attrs: { shadow: "", "no-body": "" },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "b-alert",
                            {
                              attrs: {
                                show: _vm.show,
                                variant: _vm.color,
                                dismissible: "",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.mss_alert) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("b-card-header", [
                        _c("h4", [_vm._v("Selo Agricultura Familiar - SAF")]),
                      ]),
                      _c("card-body", [
                        _c("p", { staticClass: "card-text mt-3" }, [
                          _c("strong", [
                            _vm._v(
                              "Doces, geleias, polpas de frutas, filés de pescado, aves abatidas, ovos caipiras,\n                  hortaliças"
                            ),
                          ]),
                          _vm._v(
                            " e tantos\n                outros produtos beneficiados da agricultura familiar do Rio Grande do Norte que receberem autorização\n                para uso do Selo da Agricultura Familiar (SAF-RN) são "
                          ),
                          _c("strong", [_vm._v("isentos de ICMS")]),
                          _vm._v(",\n                garantindo-lhes "),
                          _c("strong", [
                            _vm._v(
                              "um lugar de\n                  destaque, com muito mais competitividade, no mercado potiguar."
                            ),
                          ]),
                          _vm._v(" Confira o texto da lei\n                "),
                          _c("strong", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: "https://leisestaduais.com.br/rn/lei-ordinaria-n-11007-2021-rio-grande-do-norte-institui-o-selo-dos-produtos-da-agricultura-familiar-do-rio-grande-do-norte-saf-rn-e-da-outras-providencias",
                                },
                              },
                              [_vm._v("neste\n                    link")]
                            ),
                          ]),
                          _vm._v(" e o\n                decreto "),
                          _c("strong", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: "http://diariooficial.rn.gov.br/dei/dorn3/docview.aspx?id_jor=00000001&data=20220825&id_doc=784006",
                                },
                              },
                              [_vm._v("neste\n                    link")]
                            ),
                          ]),
                          _vm._v(".\n              "),
                        ]),
                        _c("p", { staticClass: "card-text" }, [
                          _c("strong", [_vm._v("O SAF-RN")]),
                          _vm._v(
                            " tem como objetivo possibilitar aos agricultores e familiares a agregação de\n                valor à produção\n                agropecuária, às atividades de pesca, aquicultura e extrativista vegetal, com vistas ao\n                desenvolvimento rural sustentável, à promoção da segurança alimentar e nutricional da população e ao\n                incremento à geração de trabalho, emprego e renda.Instrumento importante que contribui para a\n                ampliação\n                do acesso aos mercados de produtos da agricultura familiar, reforçando a estratégia que o Governo do\n                RN\n                desenvolve através do Programa Estadual de Apoio aos Circuitos Curtos de Comercialização da\n                Agricultura\n                Familiar "
                          ),
                          _c("strong", [_vm._v("(Mais Mercados)")]),
                          _vm._v(
                            " e doPrograma Estadual de Compras da Agricultura Familiar e\n                Economia Solidária\n                "
                          ),
                          _c("strong", [
                            _vm._v("(Pecafes). "),
                            _c("a", { attrs: { href: "#" } }, [
                              _vm._v("Leia a lei"),
                            ]),
                          ]),
                          _vm._v(" na íntegra.\n              "),
                        ]),
                        _c("h5", [
                          _vm._v(
                            "\n                Secretaria de Estado do Desenvolvimento Rural e Agricultura Familiar – "
                          ),
                          _c("strong", [_vm._v("SEDRAF")]),
                          _vm._v(
                            " e\n                Secretaria de estado\n                da Tributação - "
                          ),
                          _c("strong", [_vm._v("SET")]),
                          _vm._v(".\n              "),
                        ]),
                        _c("p", [
                          _vm._v(
                            "\n                A medida se tornou possível graças à aprovação de "
                          ),
                          _c("strong", [_vm._v("Lei Estadual Nº 11.007/21,")]),
                          _vm._v(
                            " de\n                autoria do Dep.\n                Estadual Souza e sancionada pela governadora Fátima Bezerra em outubro de 2021, que beneficia\n                "
                          ),
                          _c("strong", [
                            _vm._v(
                              "agricultores(as) familiares, revendedores dos produtos e os consumidores."
                            ),
                          ]),
                        ]),
                        _c("p", [
                          _vm._v(
                            "\n                No dia 30 de agosto de 2022, o Governo do RN, na presença da governadora Fátima Bezerra e de de\n                representantes de movimentos sociais, lançou a "
                          ),
                          _c("strong", [_vm._v("arte do selo")]),
                          _vm._v(
                            " e regulamentou seu uso,\n                com base em um\n                "
                          ),
                          _c("strong", [
                            _c(
                              "a",
                              { attrs: { href: "#", target: "_blank" } },
                              [_vm._v("neste decreto")]
                            ),
                          ]),
                          _vm._v(
                            ", publicado no Diário Oficial do RN.\n              "
                          ),
                        ]),
                        _c("p", [
                          _vm._v("\n                São considerados "),
                          _c("strong", [_vm._v("beneficiários do SAF/RN:")]),
                          _vm._v(
                            " agricultores familiares, empreendedores\n                familiares rurais,\n                silvicultores, aquicultores, extrativistas, povos indígenas, quilombolas e pescadores artesanais\n                profissionais, de forma individual ou coletiva, que atendam aos requisitos do "
                          ),
                          _c("strong", [
                            _vm._v(
                              "art. 3º da Lei\n                  Federal\n                  nº 11.326,"
                            ),
                          ]),
                          _vm._v(
                            " de 24 de julho de 2006, assim como os acampados da reforma agrária.\n              "
                          ),
                        ]),
                        _c("p", [
                          _c("strong", [_vm._v("Vantagens tributárias")]),
                          _vm._v("– O "),
                          _c("strong", [_vm._v("produto")]),
                          _vm._v(" das "),
                          _c("strong", [
                            _vm._v(
                              "unidades de\n                  beneficiamento"
                            ),
                          ]),
                          _vm._v(" e "),
                          _c("strong", [_vm._v("agroindústrias")]),
                          _vm._v(" da Agricultura\n                Familiar "),
                          _c("strong", [_vm._v("passa a ser isento")]),
                          _vm._v(
                            " de ICMS para quem o adquirir para revender das\n                "
                          ),
                          _c("strong", [
                            _vm._v(
                              "associações e cooperativas,\n                  bem como dos demais beneficiários,"
                            ),
                          ]),
                          _vm._v(" que possuírem o SAF-RN.\n              "),
                        ]),
                        _c("h6", [
                          _c("strong", [
                            _vm._v(
                              "Critérios para emissão e utilização do SAF- RN:"
                            ),
                          ]),
                        ]),
                        _c("section", [
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                "\n                    O SAF/RN será emitido pela Secretaria de Estado do Desenvolvimento Rural e Agricultura Familiar\n                    (SEDRAF), para utilização em produtos oriundos da agricultura familiar, mediante solicitação dos\n                    interessados.\n                  "
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                "\n                    Os interessados poderão ser pessoas físicas ou jurídicas portadoras de DAP ou CAF e cadastradas\n                    no SIRAF-NE.\n                  "
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                "\n                    Na hipótese de agricultores familiares acampados, poderá ser emitido documento específico que\n                    dará acesso ao SAF-RN.\n                  "
                              ),
                            ]),
                          ]),
                          _c("ul", [
                            _c("strong", [
                              _vm._v(
                                "Orientações para identificação e uso SAF/RN:"
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                "\n                    O SAF-RN será identificado com uma imagem específica, conforme disposto no "
                              ),
                              _c("strong", [
                                _c(
                                  "a",
                                  { attrs: { target: "_blank", href: "#" } },
                                  [
                                    _vm._v(
                                      "Manual de\n                    Identificação Visual do SAF/RN"
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(
                                ". O selo poderá ser adesivado no produto ou\n                    impresso em seu rótulo ou embalagem.\n                  "
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("vue-recaptcha", {
                ref: "invisibleRecaptcha",
                attrs: {
                  size: "invisible",
                  loadRecaptchaScript: true,
                  sitekey: _vm.recaptcha,
                },
                on: { verify: _vm.onVerify, expired: _vm.onExpired },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "section-profile-cover section-shaped my-0" },
      [
        _c("div", {
          staticClass: "shape shape-style-1 shape-primary shape-skew alpha-4",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }