var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {},
    [
      _vm._m(0),
      _vm._l(_vm.menus, function (menu) {
        return _c(
          "base-nav",
          {
            key: menu.type,
            staticClass: "mt-4",
            attrs: {
              type: menu.type,
              effect: "dark",
              expand: "",
              title: "Default Color",
              "content-id": `navbar-${menu.type}`,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content-header",
                  fn: function ({ closeMenu }) {
                    return _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-6 collapse-brand" }, [
                        _c("a", { attrs: { href: "./index.html" } }, [
                          _c("img", { attrs: { src: "img/brand/blue.png" } }),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-6 collapse-close" },
                        [
                          _c("close-button", {
                            attrs: { target: `navbar-${menu.type}` },
                            on: { click: closeMenu },
                          }),
                        ],
                        1
                      ),
                    ])
                  },
                },
              ],
              null,
              true
            ),
          },
          [_c(menu.menuComponent, { tag: "component" })],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container" }, [
      _c("h2", { staticClass: "mb-5" }, [_c("span", [_vm._v("Navbars")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }