var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section section-lg section-shaped overflow-hidden my-0" },
    [
      _vm._m(0),
      _c("div", { staticClass: "container shape-container py-0 pb-5" }, [
        _c(
          "div",
          {
            staticClass:
              "row row-grid justify-content-between align-items-center",
          },
          [
            _c("div", { staticClass: "col-lg-6" }, [
              _vm._m(1),
              _c("p", { staticClass: "lead text-white" }, [
                _vm._v(
                  "The Design System comes with four pre-built pages to help you get started\n                    faster. You can change the text and images and you're good to go. More importantly, looking at\n                    them will give you a picture of what you can built with this powerful Bootstrap 4 Design\n                    System."
                ),
              ]),
              _c(
                "div",
                { staticClass: "btn-wrapper" },
                [
                  _c(
                    "router-link",
                    { staticClass: "btn btn-success", attrs: { to: "/login" } },
                    [
                      _vm._v(
                        "\n                        Login Page\n                    "
                      ),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-success",
                      attrs: { to: "/register" },
                    },
                    [
                      _vm._v(
                        "\n                        Register Page\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-lg-5 mb-lg-auto" }, [
              _c(
                "div",
                { staticClass: "transform-perspective-right" },
                [
                  _c(
                    "card",
                    {
                      staticClass: "border-0",
                      attrs: {
                        type: "secondary",
                        shadow: "",
                        "body-classes": "px-lg-5 py-lg-5",
                        "header-classes": "pb-5 bg-white",
                      },
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          { staticClass: "text-muted text-center mb-3" },
                          [_c("small", [_vm._v("Sign in with")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "btn-wrapper text-center" },
                          [
                            _c("base-button", { attrs: { type: "neutral" } }, [
                              _c("img", {
                                attrs: {
                                  slot: "icon",
                                  src: "img/icons/common/github.svg",
                                },
                                slot: "icon",
                              }),
                              _vm._v(
                                "\n                                    Github\n                                "
                              ),
                            ]),
                            _c("base-button", { attrs: { type: "neutral" } }, [
                              _c("img", {
                                attrs: {
                                  slot: "icon",
                                  src: "img/icons/common/google.svg",
                                },
                                slot: "icon",
                              }),
                              _vm._v(
                                "\n                                    Google\n                                "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      [
                        _c(
                          "div",
                          { staticClass: "text-center text-muted mb-4" },
                          [_c("small", [_vm._v("Or sign in with credentials")])]
                        ),
                        _c(
                          "form",
                          { attrs: { role: "form" } },
                          [
                            _c("base-input", {
                              attrs: {
                                alternative: "",
                                placeholder: "Email",
                                "addon-left-icon": "ni ni-email-83",
                              },
                            }),
                            _c("base-input", {
                              attrs: {
                                alternative: "",
                                type: "password",
                                placeholder: "Password",
                                "addon-left-icon": "ni ni-lock-circle-open",
                              },
                            }),
                            _c("base-checkbox", [
                              _vm._v(
                                "\n                                    Remember me\n                                "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "base-button",
                                  {
                                    staticClass: "my-4",
                                    attrs: { type: "primary" },
                                  },
                                  [_vm._v("Sign In")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "shape shape-style-1 shape-default shape-skew" },
      [
        _c("span"),
        _c("span"),
        _c("span"),
        _c("span"),
        _c("span"),
        _c("span"),
        _c("span"),
        _c("span"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "display-3 text-white" }, [
      _vm._v("A beautiful Design System\n                    "),
      _c("span", { staticClass: "text-white" }, [
        _vm._v("completed with examples"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }