var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "custom-control custom-radio",
      class: [_vm.inlineClass, { disabled: _vm.disabled }],
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model,
            expression: "model",
          },
        ],
        staticClass: "custom-control-input",
        attrs: { id: _vm.cbId, type: "radio", disabled: _vm.disabled },
        domProps: { value: _vm.name, checked: _vm._q(_vm.model, _vm.name) },
        on: {
          change: function ($event) {
            _vm.model = _vm.name
          },
        },
      }),
      _c(
        "label",
        { staticClass: "custom-control-label", attrs: { for: _vm.cbId } },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }