var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section section-shaped section-lg my-0" },
    [
      _c("div", { staticClass: "shape shape-style-1 bg-gradient-default" }),
      _c("div", { staticClass: "container pt-lg-md" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-lg-5" },
            [
              _c(
                "card",
                {
                  staticClass: "border-0",
                  attrs: {
                    type: "secondary",
                    shadow: "",
                    "header-classes": "bg-white pb-5",
                    "body-classes": "px-lg-5 py-lg-5",
                  },
                },
                [
                  [
                    _c("div", { staticClass: "text-muted text-center mb-3" }, [
                      _c("small", [_vm._v("Digite seus dados de acesso:")]),
                    ]),
                  ],
                  [
                    _c(
                      "form",
                      { attrs: { role: "form" } },
                      [
                        _c("base-input", {
                          staticClass: "mb-3",
                          attrs: {
                            alternative: "",
                            placeholder: "Email, CPF ou CNPJ",
                            "addon-left-icon": "ni ni-circle-08",
                          },
                        }),
                        _c("base-input", {
                          attrs: {
                            alternative: "",
                            type: "password",
                            placeholder: "Senha",
                            "addon-left-icon": "ni ni-lock-circle-open",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "base-button",
                              {
                                staticClass: "my-4",
                                attrs: { type: "primary" },
                              },
                              [_vm._v("Entrar")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
              _vm._m(0),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("a", { staticClass: "text-light", attrs: { href: "#" } }, [
          _c("small", [_vm._v("Esqueceu sua senha?")]),
        ]),
      ]),
      _c("div", { staticClass: "col-6 text-right" }, [
        _c("a", { staticClass: "text-light", attrs: { href: "#" } }, [
          _c("small", [_vm._v("Criar nova conta")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }