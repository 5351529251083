var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section section-lg section-nucleo-icons pb-250" },
    [
      _c(
        "div",
        {
          staticClass: "container",
          on: {
            mouseleave: function ($event) {
              _vm.showIconsAnimation = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "row justify-content-center",
              on: {
                mouseover: function ($event) {
                  _vm.showIconsAnimation = true
                },
              },
            },
            [_vm._m(0)]
          ),
          _c("div", { staticClass: "blur--hover" }, [
            _c(
              "a",
              {
                attrs: {
                  href: "https://demos.creative-tim.com/argon-design-system/docs/foundation/icons.html",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "icons-container blur-item mt-5",
                    class: { "on-screen": _vm.showIconsAnimation },
                  },
                  [
                    _c("i", { staticClass: "icon ni ni-diamond" }),
                    _c("i", { staticClass: "icon icon-sm ni ni-album-2" }),
                    _c("i", { staticClass: "icon icon-sm ni ni-app" }),
                    _c("i", { staticClass: "icon icon-sm ni ni-atom" }),
                    _c("i", { staticClass: "icon ni ni-bag-17" }),
                    _c("i", { staticClass: "icon ni ni-bell-55" }),
                    _c("i", { staticClass: "icon ni ni-credit-card" }),
                    _c("i", { staticClass: "icon icon-sm ni ni-briefcase-24" }),
                    _c("i", { staticClass: "icon icon-sm ni ni-building" }),
                    _c("i", { staticClass: "icon icon-sm ni ni-button-play" }),
                    _c("i", { staticClass: "icon ni ni-calendar-grid-58" }),
                    _c("i", { staticClass: "icon ni ni-camera-compact" }),
                    _c("i", { staticClass: "icon ni ni-chart-bar-32" }),
                  ]
                ),
                _c("span", { staticClass: "blur-hidden h5 text-success" }, [
                  _vm._v("Explore all the 21.000+ Nucleo Icons"),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-lg-8 text-center" }, [
      _c("h2", { staticClass: "display-3" }, [_vm._v("Nucleo Icons")]),
      _c("p", { staticClass: "lead" }, [
        _vm._v(
          "\n                    The official package contains over 21.000 icons which are looking great in combination with\n                    Argon Design System. Make sure you check all of them and use those that you like the most.\n                "
        ),
      ]),
      _c("div", { staticClass: "btn-wrapper" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary",
            attrs: {
              href: "https://demos.creative-tim.com/argon-design-system/docs/foundation/icons.html",
            },
          },
          [_vm._v("View demo icons")]
        ),
        _c(
          "a",
          {
            staticClass: "btn btn-default mt-3 mt-md-0",
            attrs: {
              href: "https://nucleoapp.com/?ref=1712",
              target: "_blank",
            },
          },
          [_vm._v("View\n                        all icons")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }