var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "section section-components pb-0",
      attrs: { id: "section-components" },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-lg-12" },
            [
              _vm._m(0),
              _c(
                "h3",
                { staticClass: "h4 text-success font-weight-bold mb-4" },
                [_vm._v("Buttons")]
              ),
              _c(
                "div",
                [
                  _c("base-button", { attrs: { type: "primary" } }, [
                    _vm._v("Button"),
                  ]),
                  _c(
                    "base-button",
                    {
                      staticClass: "btn-3",
                      attrs: { type: "primary", icon: "ni ni-bag-17" },
                    },
                    [_vm._v("Button")]
                  ),
                  _c("base-button", {
                    staticClass: "btn-2",
                    attrs: { type: "primary", icon: "ni ni-bag-17" },
                  }),
                  _vm._m(1),
                  _c(
                    "base-button",
                    { attrs: { type: "primary", size: "sm" } },
                    [_vm._v("Small")]
                  ),
                  _c("base-button", { attrs: { type: "primary" } }, [
                    _vm._v("Regular"),
                  ]),
                  _c(
                    "base-button",
                    { attrs: { type: "primary", size: "lg" } },
                    [_vm._v("Large Button")]
                  ),
                ],
                1
              ),
              _vm._m(2),
              _c(
                "base-button",
                { staticClass: "btn-1", attrs: { type: "primary" } },
                [_vm._v("Primary")]
              ),
              _c(
                "base-button",
                { staticClass: "btn-1", attrs: { type: "info" } },
                [_vm._v("Info")]
              ),
              _c(
                "base-button",
                { staticClass: "btn-1", attrs: { type: "success" } },
                [_vm._v("Success")]
              ),
              _c(
                "base-button",
                { staticClass: "btn-1", attrs: { type: "warning" } },
                [_vm._v("Warning")]
              ),
              _c(
                "base-button",
                { staticClass: "btn-1", attrs: { type: "danger" } },
                [_vm._v("Danger")]
              ),
              _c(
                "base-button",
                { staticClass: "btn-1", attrs: { type: "neutral" } },
                [_vm._v("Neutral")]
              ),
              _vm._m(3),
              _c(
                "base-button",
                {
                  staticClass: "btn-1",
                  attrs: { outline: "", type: "primary" },
                },
                [_vm._v("Outline Primary")]
              ),
              _c(
                "base-button",
                { staticClass: "btn-1", attrs: { outline: "", type: "info" } },
                [_vm._v("Outline Info")]
              ),
              _c(
                "base-button",
                {
                  staticClass: "btn-1",
                  attrs: { outline: "", type: "success" },
                },
                [_vm._v("Outline Success")]
              ),
              _c(
                "base-button",
                {
                  staticClass: "btn-1",
                  attrs: { outline: "", type: "warning" },
                },
                [_vm._v("Outline Warning")]
              ),
              _c(
                "base-button",
                {
                  staticClass: "btn-1",
                  attrs: { outline: "", type: "danger" },
                },
                [_vm._v("Outline Danger")]
              ),
              _c(
                "base-button",
                {
                  staticClass: "btn-1",
                  attrs: { outline: "", type: "neutral" },
                },
                [_vm._v("Outline Neutral")]
              ),
              _vm._m(4),
              _c(
                "a",
                {
                  staticClass: "btn btn-link text-default",
                  attrs: { href: "#" },
                },
                [_vm._v("Default")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-link text-primary",
                  attrs: { href: "#" },
                },
                [_vm._v("Primary")]
              ),
              _c(
                "a",
                { staticClass: "btn btn-link text-info", attrs: { href: "#" } },
                [_vm._v("Info")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-link text-success",
                  attrs: { href: "#" },
                },
                [_vm._v("Success")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-link text-warning",
                  attrs: { href: "#" },
                },
                [_vm._v("Warning")]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-link text-danger",
                  attrs: { href: "#" },
                },
                [_vm._v("Danger")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "mb-5" }, [
      _c("span", [_vm._v("Basic Elements")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3 mt-5" }, [
      _c("small", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("Pick your size"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3 mt-5" }, [
      _c("small", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("Pick your color"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3 mt-5" }, [
      _c("small", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("Outline"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3 mt-5" }, [
      _c("small", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("Links"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }