var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section section-components" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("h3", { staticClass: "h4 text-success font-weight-bold mb-4" }, [
          _vm._v("Tabs"),
        ]),
        _c("tabs-section"),
        _c("progress-section"),
        _c("div", { staticClass: "row row-grid justify-content-between" }, [
          _c(
            "div",
            { staticClass: "col-lg-5" },
            [
              _c(
                "h3",
                { staticClass: "h4 text-success font-weight-bold mb-5" },
                [_vm._v("Navigation Pills")]
              ),
              _c(
                "tabs",
                { attrs: { fill: false, circle: "" } },
                [
                  _c("tab-pane", [
                    _c(
                      "span",
                      {
                        staticClass: "nav-link-icon d-block",
                        attrs: { slot: "title" },
                        slot: "title",
                      },
                      [_c("i", { staticClass: "ni ni-atom" })]
                    ),
                  ]),
                  _c("tab-pane", [
                    _c(
                      "span",
                      {
                        staticClass: "nav-link-icon d-block",
                        attrs: { slot: "title" },
                        slot: "title",
                      },
                      [_c("i", { staticClass: "ni ni-chat-round" })]
                    ),
                  ]),
                  _c("tab-pane", [
                    _c(
                      "span",
                      {
                        staticClass: "nav-link-icon d-block",
                        attrs: { slot: "title" },
                        slot: "title",
                      },
                      [_c("i", { staticClass: "ni ni-cloud-download-95" })]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-lg-5" },
            [
              _c(
                "h3",
                { staticClass: "h4 text-success font-weight-bold mb-5" },
                [_vm._v("Labels")]
              ),
              _c(
                "badge",
                { staticClass: "text-uppercase", attrs: { type: "primary" } },
                [_vm._v("Primary")]
              ),
              _c(
                "badge",
                { staticClass: "text-uppercase", attrs: { type: "success" } },
                [_vm._v("Success")]
              ),
              _c(
                "badge",
                { staticClass: "text-uppercase", attrs: { type: "danger" } },
                [_vm._v("Danger")]
              ),
              _c(
                "badge",
                { staticClass: "text-uppercase", attrs: { type: "warning" } },
                [_vm._v("Warning")]
              ),
              _c(
                "badge",
                { staticClass: "text-uppercase", attrs: { type: "info" } },
                [_vm._v("Info")]
              ),
            ],
            1
          ),
        ]),
        _vm._m(0),
        _c(
          "base-alert",
          { attrs: { type: "success", icon: "ni ni-like-2", dismissible: "" } },
          [
            _c("span", { attrs: { slot: "text" }, slot: "text" }, [
              _c("strong", [_vm._v("Success!")]),
              _vm._v(" This is a success alert—check it out!"),
            ]),
          ]
        ),
        _c(
          "base-alert",
          { attrs: { type: "info", icon: "ni ni-bell-55", dismissible: "" } },
          [
            _c("span", { attrs: { slot: "text" }, slot: "text" }, [
              _c("strong", [_vm._v("Info!")]),
              _vm._v(" This is an info alert—check it out!"),
            ]),
          ]
        ),
        _c(
          "base-alert",
          {
            attrs: { type: "warning", icon: "ni ni-bell-55", dismissible: "" },
          },
          [
            _c("span", { attrs: { slot: "text" }, slot: "text" }, [
              _c("strong", [_vm._v("Warning!")]),
              _vm._v(" This is a warning alert—check it out!"),
            ]),
          ]
        ),
        _c(
          "base-alert",
          {
            attrs: {
              type: "danger",
              icon: "ni ni-support-16",
              dismissible: "",
            },
          },
          [
            _c("span", { attrs: { slot: "text" }, slot: "text" }, [
              _c("strong", [_vm._v("Danger!")]),
              _vm._v(" This is an error alert—check it out!"),
            ]),
          ]
        ),
        _c("typography"),
        _vm._m(1),
        _c("images"),
        _vm._m(2),
        _c("h3", { staticClass: "h4 text-success font-weight-bold mb-4" }, [
          _vm._v("Modals"),
        ]),
        _c("modals"),
        _vm._v("\n         Datepicker\n        "),
        _c(
          "h3",
          { staticClass: "h4 text-success font-weight-bold mt-md mb-4" },
          [_vm._v("Datepicker")]
        ),
        _c("date-pickers"),
        _c(
          "h3",
          { staticClass: "h4 text-success font-weight-bold mt-md mb-4" },
          [_vm._v("Tooltips & Popovers")]
        ),
        _c("tooltips"),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "mt-lg mb-4" }, [
      _c("span", [_vm._v("Alerts")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "mt-lg mb-5" }, [
      _c("span", [_vm._v("Images")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "mt-lg mb-5" }, [
      _c("span", [_vm._v("Javascript Components")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }