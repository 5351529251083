var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.dev
        ? _c(
            "base-alert",
            {
              staticClass: "text-center",
              attrs: { type: "warning", dismissible: "" },
            },
            [
              _c("span", { staticClass: "alert-inner--text text-dark" }, [
                _c("strong", [
                  _vm._v("Atenção! \n      "),
                  _c("br"),
                  _vm._v(
                    "\n        Você está utilizando uma instância de testes\n      do SIRAF NE.\n      "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n      Todos os dados presentes neste sistema não são oficiais e\n      podem ser editados e/ou apagados a qualquer momento sem aviso\n      prévio."
                  ),
                ]),
              ]),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "alert",
                    "aria-label": "Close",
                  },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("router-view", { attrs: { name: "header" } }),
      _c(
        "main",
        [
          _c(
            "fade-transition",
            { attrs: { origin: "center", mode: "out-in", duration: 250 } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
      _c("router-view", { attrs: { name: "footer" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }