var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "footer has-cards" }, [
    _c("section", { staticClass: "section section-sm" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row row-grid justify-content-center" }, [
          _c("div", { staticClass: "col-lg-12 text-center" }, [
            _c("p", { staticClass: "lead" }, [
              _vm._v(
                "\n            O Sistema de Informação Regional da Agricultura Familiar no\n            Nordeste é resultado da parceria entre:\n          "
              ),
            ]),
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row justify-content-md-between" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", { staticClass: "row row-grid" }, [
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://funarbe.org.br/",
                            target: "_blank",
                            "data-toggle": "tooltip",
                            "data-original-title": "Fundação Arthur Bernardes",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: "img/brand/others/funarbe.png",
                                expression: "'img/brand/others/funarbe.png'",
                              },
                            ],
                            staticClass: "img-fluid",
                            attrs: {
                              alt: "Logo da Fundação Arthur Bernardes. Fonte na cor azul. Sigla FUNARB em caixa alta na linha de cima \n                        e envolvido por duas barras horizontais e a baixo segue o texto 'Fundação Arthur Bernardes' com uma fonte menor.  ",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://www.ufv.br/",
                            target: "_blank",
                            "data-toggle": "tooltip",
                            "data-original-title":
                              "UFV - Universidade Federal de Viçosa",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: "img/brand/others/ufv.png",
                                expression: "'img/brand/others/ufv.png'",
                              },
                            ],
                            staticClass: "img-fluid",
                            attrs: {
                              alt: "Logo da UFV. É divido em 3 linhas. Na primeira tem a sigla 'UFV' em cor preto. Abaixo tem o texto \n                        'Universidade Federal de Viçosa' e mais abaixo três barras horizontais lado a lado separadas por um espaço. \n                        Suas cores são preto, vermelho e amarelo.",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://www.ippds.ufv.br/",
                            target: "_blank",
                            "data-toggle": "tooltip",
                            "data-original-title":
                              "IPPDS - Instituto de Políticas Públicas e Desenvolvimento Sustentável",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: "img/brand/others/ippds.png",
                                expression: "'img/brand/others/ippds.png'",
                              },
                            ],
                            staticClass: "img-fluid",
                            attrs: {
                              alt: "Logo da IPPDS. É dividido em 4 linhas. Na primeira, uma imagem de um galho com folhas circundando \n                        três bonequinhos com seus braços erguidos, tudo na cor verde com um gradiente.\n                         Os bonecos estão se projetando a partir de duas folhas pequenas. Na linha dois, tem a sigla 'IPPDS'. \n                         Na linha três, o texto 'Instituto de Políticas Públicas e'. Na quarta linha o texto 'Desenvolvimento Sustentável' em uma fonte menor.",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://aksaam.ufv.br/",
                            "data-toggle": "tooltip",
                            "data-original-title":
                              "AKSAAM - Adaptando Conhecimento para a Agricultura Sustentável e o Acesso a Mercados",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: "img/brand/others/aksaam.png",
                                expression: "'img/brand/others/aksaam.png'",
                              },
                            ],
                            staticClass: "img-fluid",
                            attrs: {
                              alt: "Logo AKSAAM. Dividido em quatro linhas. Na primeira, a sigla 'AKSAAM' na cor marron claro. \n                        Na segunda, uma linha espessa verde com uma folha de árvore no final. Na terceira, o texto 'Adaptando Conhecimento para a' na cor marron. \n                        E na última, o texto 'Agricultura Sustentável e o Acesso a Mercados' em fonte menor e na cor marrom",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://uern.br",
                            target: "_blank",
                            "data-toggle": "tooltip",
                            "data-original-title":
                              "UERN - Universidade do Estado do Rio Grande do Norte",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: "img/brand/others/uern.png",
                                expression: "'img/brand/others/uern.png'",
                              },
                            ],
                            staticClass: "img-fluid",
                            attrs: {
                              alt: "Logo UERN. Sigla 'UERN' na cor azul escuro.",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "http://www.consorcionordeste-ne.com.br/",
                            target: "_blank",
                            "data-toggle": "tooltip",
                            "data-original-title":
                              "Consórcio Nordeste: O Brasil que cresce unido.",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: "img/brand/others/c_nordeste.png",
                                expression: "'img/brand/others/c_nordeste.png'",
                              },
                            ],
                            staticClass: "img-fluid",
                            attrs: {
                              alt: "Logo dividida em três linhas. Na primeira, o texto 'CONSÓRCIO'. Na segunda, o texto 'NORDESTE', \n                      este tem o primeiro 'E' formado pela base de uma imagem que remete ao mapa de barras coloridas (Gradiente que vai do vermelho, amarelo ao verde escuro) da região nordeste.\n                       E na última linha, o texto 'O BRASIL QUE CRESCE UNIDO'. ",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "http://www.rn.gov.br/",
                            target: "_blank",
                            "data-toggle": "tooltip",
                            "data-original-title":
                              "Secretaria de Estado do Desenvolvimento Rural e da Agricultura Familiar",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: "img/brand/others/sedraf.png",
                                expression: "'img/brand/others/sedraf.png'",
                              },
                            ],
                            staticClass: "img-fluid",
                            attrs: {
                              alt: "Logo do governo do RN. Formado pelo brazão do RN a esquerda. Na direita, o texto 'RIO GRANDE DO NORTE' ocupando duas linhas. \n                      Abaixo, tem quatro pequenas barras horizontais coloridas. Na outra linha o texto 'GOVERNO DO ESTADO, em um fonte menor e  mais abaixo, o texto \n                      'Secretaria de Estado do Desenvolvimento Rural e da Agricultura Familiar' em caixa alta e uma fonte menor. ",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://www.fida.org.br/",
                            target: "_blank",
                            "data-toggle": "tooltip",
                            "data-original-title":
                              "FIDA - Fundo Internacional de Desenvolvimento Agrícola",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: "img/brand/others/fida.png",
                                expression: "'img/brand/others/fida.png'",
                              },
                            ],
                            staticClass: "img-fluid",
                            attrs: {
                              alt: " Logo do FIDA. Possuí uma imagem cinza do TRIGO a esquerda. Ao lado a sigla em preto do FIDA. E abaixo de todos, o texto 'Investindo nas populações rurais', na cor preto.",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "container" }, [
      _c("hr"),
      _c(
        "div",
        { staticClass: "row align-items-center justify-content-md-between" },
        [
          _c("div", { staticClass: "col-md-6" }, [
            _c("div", { staticClass: "copyright" }, [
              _vm._v(
                "\n          © " +
                  _vm._s(_vm.year) +
                  "\n          SIRAF-NE\n        "
              ),
            ]),
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _c("ul", { staticClass: "nav nav-footer justify-content-end" }, [
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/contatos", role: "button" },
                    },
                    [_vm._v("Contatos")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "#", role: "button" },
                    },
                    [_vm._v("Sobre")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }