var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("highcharts", {
        ref: "chart",
        staticClass: "hc",
        attrs: {
          constructorType: "mapChart",
          options: _vm.chartOptions,
          id: "container",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }