var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section pb-0 section-components" }, [
    _c("div", { staticClass: "container mb-5" }, [
      _c("h3", { staticClass: "h4 text-success font-weight-bold mb-4" }, [
        _vm._v("Inputs"),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-lg-4 col-sm-6" },
          [
            _c("base-input", { attrs: { placeholder: "Regular" } }),
            _c("base-input", {
              attrs: {
                placeholder: "Search",
                "addon-left-icon": "ni ni-zoom-split-in",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-lg-4 col-sm-6" },
          [
            _c("base-input", {
              attrs: { placeholder: "Regular", disabled: "" },
            }),
            _c("base-input", {
              attrs: {
                placeholder: "Birthday",
                "addon-right-icon": "ni ni-zoom-split-in",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-lg-4 col-sm-6" },
          [
            _c("base-input", {
              attrs: { placeholder: "Success", valid: true },
            }),
            _c("base-input", {
              attrs: { placeholder: "Success", valid: false },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "py-5 bg-secondary" }, [
      _c("div", { staticClass: "container" }, [
        _vm._m(1),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-4 col-sm-6" },
            [
              _c("base-input", {
                attrs: { alternative: "", placeholder: "Regular" },
              }),
              _c("base-input", {
                attrs: {
                  alternative: "",
                  placeholder: "Search",
                  "addon-left-icon": "ni ni-zoom-split-in",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-lg-4 col-sm-6" },
            [
              _c("base-input", {
                attrs: {
                  alternative: "",
                  placeholder: "Regular",
                  disabled: "",
                },
              }),
              _c("base-input", {
                attrs: {
                  alternative: "",
                  placeholder: "Birthday",
                  "addon-right-icon": "ni ni-zoom-split-in",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-lg-4 col-sm-6" },
            [
              _c("base-input", {
                attrs: { alternative: "", placeholder: "Success", valid: true },
              }),
              _c("base-input", {
                attrs: {
                  alternative: "",
                  placeholder: "Success",
                  valid: false,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("small", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("Form controls"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("small", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("Form controls (alternative)"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }