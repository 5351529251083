var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { staticClass: "section section section-shaped my-0 overflow-hidden" },
      [
        _vm._m(0),
        _c("div", { staticClass: "container py-5" }, [
          _c("div", { staticClass: "row row-grid align-items-center" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "col-lg-6 order-lg-1" },
              [
                _c("div", { staticClass: "d-flex px-3" }, [
                  _c(
                    "div",
                    [
                      _c("icon", {
                        staticClass: "bg-gradient-white",
                        attrs: {
                          name: "ni ni-chat-round",
                          size: "lg",
                          color: "success",
                          shadow: "",
                          rounded: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._m(2),
                ]),
                _c(
                  "card",
                  {
                    staticClass: "shadow-lg--hover mt-5",
                    attrs: { shadow: "" },
                  },
                  [
                    _c("div", { staticClass: "d-flex px-3" }, [
                      _c(
                        "div",
                        [
                          _c("icon", {
                            attrs: {
                              name: "ni ni-email-83",
                              gradient: "success",
                              color: "white",
                              shadow: "",
                              rounded: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "pl-4" }, [
                        _c("h5", { staticClass: "title text-success" }, [
                          _vm._v(
                            "Está com problemas, dificuldades ao utilizar o SIRAF-NE?\n                                    Envie um e-mail para:"
                          ),
                        ]),
                        _c("p", [
                          _c("strong", [_vm._v("suporte.sirafne@uern.br")]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "card",
                  {
                    staticClass: "shadow-lg--hover mt-5",
                    attrs: { shadow: "" },
                  },
                  [
                    _c("div", { staticClass: "d-flex px-3" }, [
                      _c(
                        "div",
                        [
                          _c("icon", {
                            attrs: {
                              name: "ni ni-send",
                              gradient: "warning",
                              color: "white",
                              shadow: "",
                              rounded: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "pl-4" }, [
                        _c("h5", { staticClass: "title text-warning" }, [
                          _vm._v(
                            "Deseja mais informações sobre o SIRAF-NE? Envie um e-mail\n                                    para:"
                          ),
                        ]),
                        _c("p", [_c("strong", [_vm._v("sirafne@uern.br")])]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
    _c(
      "section",
      { staticClass: "section section section-shaped my-0 overflow-hidden" },
      [
        _c("div", { staticClass: "container py-0" }, [
          _c("div", { staticClass: "d-flex px-3" }, [
            _c(
              "div",
              [
                _c("icon", {
                  staticClass: "bg-gradient-success",
                  attrs: {
                    name: "ni ni-notification-70",
                    size: "lg",
                    color: "white",
                    shadow: "",
                    rounded: "",
                  },
                }),
              ],
              1
            ),
            _vm._m(3),
          ]),
          _c(
            "div",
            { staticClass: "accordion mt-5", attrs: { role: "tablist" } },
            [
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-1",
                              modifiers: { "accordion-1": true },
                            },
                          ],
                          staticClass: "text-left",
                          attrs: { block: "", variant: "success" },
                        },
                        [_vm._v("Pergunta 1\n                        ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-1",
                        visible: "",
                        accordion: "my-accordion",
                        role: "tabpanel",
                      },
                    },
                    [
                      _c(
                        "b-card-body",
                        [_c("b-card-text", [_vm._v(_vm._s(_vm.text))])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-2",
                              modifiers: { "accordion-2": true },
                            },
                          ],
                          staticClass: "text-left",
                          attrs: { block: "", variant: "success" },
                        },
                        [_vm._v("Pergunta 2\n                        ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-2",
                        accordion: "my-accordion",
                        role: "tabpanel",
                      },
                    },
                    [
                      _c(
                        "b-card-body",
                        [_c("b-card-text", [_vm._v(_vm._s(_vm.text))])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-3",
                              modifiers: { "accordion-3": true },
                            },
                          ],
                          staticClass: "text-left",
                          attrs: { block: "", variant: "success" },
                        },
                        [_vm._v("Pergunta 3")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-3",
                        accordion: "my-accordion",
                        role: "tabpanel",
                      },
                    },
                    [
                      _c(
                        "b-card-body",
                        [_c("b-card-text", [_vm._v(_vm._s(_vm.text))])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "shape shape-style-1 bg-gradient-success shape-skew" },
      [_c("span"), _c("span"), _c("span"), _c("span"), _c("span"), _c("span")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6 order-lg-2 ml-lg-auto" }, [
      _c("div", { staticClass: "position-relative pl-md-5" }, [
        _c("img", {
          staticClass: "img-center img-fluid",
          attrs: { src: "img/ill/ill-2.svg" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pl-4" }, [
      _c("h4", { staticClass: "display-3 text-white" }, [_vm._v("Contatos")]),
      _c("p", { staticClass: "text-white" }, [
        _vm._v(
          "Caso necessite tirar alguma dúvida sobre seu cadastro no SIRAF-NE, nos\n                                envie um e-mail!"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pl-4" }, [
      _c("h5", { staticClass: "display-3 text-success" }, [
        _vm._v("Perguntas mais frequentes:"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }