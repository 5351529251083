import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Components from "./views/Components.vue";
import Landing from "./views/Landing.vue";
import Login from "./views/Login.vue";
import Profile from "./views/Profile.vue";
import Library from "./views/Library.vue";
import BankOfPrice from "./views/BankOfPrice.vue";
import Home from "./views/Home.vue";
import Vitrine from "./views/Vitrine.vue";
import Contatos from "./views/Contatos.vue";
import Stamp from "./views/RN/Stamp.vue";
import Validations from "./views/Validations.vue";


Vue.use(Router);

export default new Router({
  base: process.env.BASE_URL,
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "home",
      components: {
        header: AppHeader,
        default: Home,
        footer: AppFooter
      }
    },
    {
      path: "/contatos",
      name: "contatos",
      components: {
        header: AppHeader,
        default: Contatos,
        footer: AppFooter
      }
    },
    {
      path: "/componentes",
      name: "components",
      components: {
        header: AppHeader,
        default: Components,
        footer: AppFooter
      }
    },
    {
      path: "/landing",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter
      }
    },
    {
      path: "/login",
      name: "login",
      components: {
        header: AppHeader,
        default: Login,
        footer: AppFooter
      }
    },   
    {
      path: "/profile",
      name: "profile",
      components: {
        header: AppHeader,
        default: Profile,
        footer: AppFooter
      }
    },
    {
      path: "/biblioteca",
      name: "library",
      components: {
        header: AppHeader,
        default: Library,
        footer: AppFooter
      }
    },
    {
      path: "/validations",
      name: "validations",
      components: {
        header: AppHeader,
        default: Validations,
        footer: AppFooter
      }
    },
    {
      path: "/bancodeprecos",
      name: "bankofprice",
      components: {
        header: AppHeader,
        default: BankOfPrice,
        footer: AppFooter
      }
    },
    {
      path: "/vitrine",
      name: "vitrine",
      components: {
        header: AppHeader,
        default: Vitrine,
        footer: AppFooter
      }
    },
    {
      path: "/RN-SAF",
      name: "stamp",
      components: {
        header: AppHeader,
        default: Stamp,
        footer: AppFooter
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
