var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("hero"),
      _c("basic-elements"),
      _c("inputs"),
      _c("custom-controls"),
      _c("navigation"),
      _c("javascript-components"),
      _c("icons"),
      _c("examples"),
      _c("download-section"),
      _c("carousel"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }