var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-4" },
      [
        _c(
          "base-button",
          {
            staticClass: "mb-3",
            attrs: { block: "", type: "primary" },
            on: {
              click: function ($event) {
                _vm.modals.modal1 = true
              },
            },
          },
          [_vm._v("\n            Default\n        ")]
        ),
        _c(
          "modal",
          {
            attrs: { show: _vm.modals.modal1 },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.modals, "modal1", $event)
              },
            },
          },
          [
            _c(
              "h6",
              {
                staticClass: "modal-title",
                attrs: { slot: "header", id: "modal-title-default" },
                slot: "header",
              },
              [_vm._v("Type your modal title")]
            ),
            _c("p", [
              _vm._v(
                "Far far away, behind the word mountains, far from the countries Vokalia and\n                Consonantia, there live the blind texts. Separated they live in Bookmarksgrove\n                right at the coast of the Semantics, a large language ocean."
              ),
            ]),
            _c("p", [
              _vm._v(
                "A small river named Duden flows by their place and supplies it with the necessary\n                regelialia. It is a paradisematic country, in which roasted parts of sentences\n                fly into your mouth."
              ),
            ]),
            _c(
              "template",
              { slot: "footer" },
              [
                _c("base-button", { attrs: { type: "primary" } }, [
                  _vm._v("Save changes"),
                ]),
                _c(
                  "base-button",
                  {
                    staticClass: "ml-auto",
                    attrs: { type: "link" },
                    on: {
                      click: function ($event) {
                        _vm.modals.modal1 = false
                      },
                    },
                  },
                  [_vm._v("Close\n                ")]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-md-4" },
      [
        _c(
          "base-button",
          {
            staticClass: "mb-3",
            attrs: { block: "", type: "warning" },
            on: {
              click: function ($event) {
                _vm.modals.modal2 = true
              },
            },
          },
          [_vm._v("\n            Notification\n        ")]
        ),
        _c(
          "modal",
          {
            attrs: {
              show: _vm.modals.modal2,
              gradient: "danger",
              "modal-classes": "modal-danger modal-dialog-centered",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.modals, "modal2", $event)
              },
            },
          },
          [
            _c(
              "h6",
              {
                staticClass: "modal-title",
                attrs: { slot: "header", id: "modal-title-notification" },
                slot: "header",
              },
              [_vm._v("Your attention is required")]
            ),
            _c("div", { staticClass: "py-3 text-center" }, [
              _c("i", { staticClass: "ni ni-bell-55 ni-3x" }),
              _c("h4", { staticClass: "heading mt-4" }, [
                _vm._v("You should read this!"),
              ]),
              _c("p", [
                _vm._v(
                  "A small river named Duden flows by their place and supplies it with the\n                    necessary regelialia."
                ),
              ]),
            ]),
            _c(
              "template",
              { slot: "footer" },
              [
                _c("base-button", { attrs: { type: "white" } }, [
                  _vm._v("Ok, Got it"),
                ]),
                _c(
                  "base-button",
                  {
                    staticClass: "ml-auto",
                    attrs: { type: "link", "text-color": "white" },
                    on: {
                      click: function ($event) {
                        _vm.modals.modal2 = false
                      },
                    },
                  },
                  [_vm._v("\n                    Close\n                ")]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-md-4" },
      [
        _c(
          "base-button",
          {
            staticClass: "mb-3",
            attrs: { block: "", type: "default" },
            on: {
              click: function ($event) {
                _vm.modals.modal3 = true
              },
            },
          },
          [_vm._v("\n            Form\n        ")]
        ),
        _c(
          "modal",
          {
            attrs: {
              show: _vm.modals.modal3,
              "body-classes": "p-0",
              "modal-classes": "modal-dialog-centered modal-sm",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.modals, "modal3", $event)
              },
            },
          },
          [
            _c(
              "card",
              {
                staticClass: "border-0",
                attrs: {
                  type: "secondary",
                  shadow: "",
                  "header-classes": "bg-white pb-5",
                  "body-classes": "px-lg-5 py-lg-5",
                },
              },
              [
                [
                  _c("div", { staticClass: "text-muted text-center mb-3" }, [
                    _c("small", [_vm._v("Sign in with")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "btn-wrapper text-center" },
                    [
                      _c("base-button", { attrs: { type: "neutral" } }, [
                        _c("img", {
                          attrs: {
                            slot: "icon",
                            src: "img/icons/common/github.svg",
                          },
                          slot: "icon",
                        }),
                        _vm._v(
                          "\n                            Github\n                        "
                        ),
                      ]),
                      _c("base-button", { attrs: { type: "neutral" } }, [
                        _c("img", {
                          attrs: {
                            slot: "icon",
                            src: "img/icons/common/google.svg",
                          },
                          slot: "icon",
                        }),
                        _vm._v(
                          "\n                            Google\n                        "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                [
                  _c("div", { staticClass: "text-center text-muted mb-4" }, [
                    _c("small", [_vm._v("Or sign in with credentials")]),
                  ]),
                  _c(
                    "form",
                    { attrs: { role: "form" } },
                    [
                      _c("base-input", {
                        staticClass: "mb-3",
                        attrs: {
                          alternative: "",
                          placeholder: "Email",
                          "addon-left-icon": "ni ni-email-83",
                        },
                      }),
                      _c("base-input", {
                        attrs: {
                          alternative: "",
                          type: "password",
                          placeholder: "Password",
                          "addon-left-icon": "ni ni-lock-circle-open",
                        },
                      }),
                      _c("base-checkbox", [
                        _vm._v(
                          "\n                            Remember me\n                        "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "base-button",
                            { staticClass: "my-4", attrs: { type: "primary" } },
                            [_vm._v("Sign In")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }