var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profile-page" }, [
    _vm._m(0),
    _c("section", { staticClass: "section section-skew" }, [
      _c(
        "div",
        { staticClass: "container col-6" },
        [
          _c(
            "card",
            { staticClass: "card-profile", attrs: { shadow: "" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "b-alert",
                            {
                              attrs: {
                                show: _vm.show,
                                variant: _vm.color,
                                dismissible: "",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.mss_alert) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.validate.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("label", [
                            _vm._v("Informe a chave de validação:"),
                          ]),
                          _c("b-form-input", {
                            attrs: {
                              id: "input",
                              required: "",
                              placeholder: "informe a chave de validação",
                              maxlength: "40",
                              description: "Caracteres restantes",
                            },
                            model: {
                              value: _vm.chave,
                              callback: function ($$v) {
                                _vm.chave = $$v
                              },
                              expression: "chave",
                            },
                          }),
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-5",
                              attrs: {
                                disabled:
                                  _vm.chave.length === 40 ? false : true,
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.validate()
                                },
                              },
                            },
                            [_vm._v("Validar")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("vue-recaptcha", {
                ref: "invisibleRecaptcha",
                attrs: {
                  size: "invisible",
                  loadRecaptchaScript: true,
                  sitekey: _vm.recaptcha,
                },
                on: { verify: _vm.onVerify, expired: _vm.onExpired },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "section-profile-cover section-shaped my-0" },
      [
        _c("div", {
          staticClass: "shape shape-style-1 shape-primary shape-skew alpha-4",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }