import Vue from 'vue'
import { baseApiUrl } from "@/global";
import axios from 'axios'

axios.defaults.baseURL = baseApiUrl 
// axios.defaults.baseURL = 'https://sirafnedev.lordi.uern.br/api/v1/virtual-library/publication/'

/* axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers['Access-Control-Allow-Methods'] = 'GET'; */

Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios
    }
})