var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "header-global" },
    [
      _c(
        "base-nav",
        {
          staticClass: "navbar-main",
          attrs: { transparent: "", type: "", effect: "light", expand: "" },
          scopedSlots: _vm._u([
            {
              key: "content-header",
              fn: function ({ closeMenu }) {
                return _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6 collapse-brand" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _c("img", { attrs: { src: "img/brand/logo.png" } }),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-6 collapse-close" },
                    [_c("close-button", { on: { click: closeMenu } })],
                    1
                  ),
                ])
              },
            },
          ]),
        },
        [
          _c(
            "router-link",
            {
              staticClass: "navbar-brand mr-lg-5",
              attrs: { slot: "brand", to: "/" },
              slot: "brand",
            },
            [_c("img", { attrs: { src: "img/brand/logo.png", alt: "logo" } })]
          ),
          _c(
            "ul",
            {
              staticClass: "navbar-nav navbar-nav-hover align-items-lg-center",
            },
            [
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/vitrine", role: "button" },
                    },
                    [_vm._v("Vitrine")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/bancodeprecos", role: "button" },
                    },
                    [_vm._v("Banco de Preços")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/biblioteca", role: "button" },
                    },
                    [_vm._v("Biblioteca")]
                  ),
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/Validations", role: "button" },
                    },
                    [_vm._v("Validações")]
                  ),
                ],
                1
              ),
              _c(
                "base-dropdown",
                {
                  staticClass: "nav-item",
                  attrs: { "menu-classes": "dropdown-menu-xl" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        slot: "title",
                        href: "#",
                        "data-toggle": "dropdown",
                        role: "button",
                      },
                      slot: "title",
                    },
                    [
                      _c("i", { staticClass: "ni ni-ui-04 d-lg-none" }),
                      _c("span", { staticClass: "nav-link-inner--text" }, [
                        _vm._v("Manuais"),
                      ]),
                    ]
                  ),
                  _c("li", [
                    _c("div", { staticClass: "dropdown-menu-inner" }, [
                      _c(
                        "a",
                        {
                          staticClass: "media d-flex align-items-center",
                          attrs: {
                            href: "docs/Manual_Usuario_SIRAF-NE_V1.pdf",
                            target: "_blank",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "icon icon-shape bg-gradient-primary rounded-circle text-white",
                            },
                            [_c("i", { staticClass: "ni ni-single-copy-04" })]
                          ),
                          _c("div", { staticClass: "media-body ml-3" }, [
                            _c(
                              "h6",
                              { staticClass: "heading text-primary mb-md-1" },
                              [_vm._v("Manual do Usuário")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "description d-none d-md-inline-block mb-0",
                              },
                              [
                                _vm._v(
                                  "\n                  Download do Manual do Usuário Externo do SIRAF-NE na versão\n                  1.0 que auxilia na criação de conta de usuários e\n                  preenchimento dos perfis.\n                "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "dropdown-menu-inner" }, [
                      _c(
                        "a",
                        {
                          staticClass: "media d-flex align-items-center",
                          attrs: {
                            href: "docs/SIRAF-NE_Política_de_Privacidade.pdf",
                            target: "_blank",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "icon icon-shape bg-gradient-success rounded-circle text-white",
                            },
                            [_c("i", { staticClass: "ni ni-key-25" })]
                          ),
                          _c("div", { staticClass: "media-body ml-3" }, [
                            _c(
                              "h6",
                              { staticClass: "heading text-success mb-md-1" },
                              [
                                _vm._v(
                                  "\n                  Política de Privacidade\n                "
                                ),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "description d-none d-md-inline-block mb-0",
                              },
                              [
                                _vm._v(
                                  "\n                  Este documento possui informações sobre o quais tratamentos sobre os\n                  dados pessoais dos usuários são realizados.\n                "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("div", { staticClass: "dropdown-menu-inner" }, [
                      _c(
                        "a",
                        {
                          staticClass: "media d-flex align-items-center",
                          attrs: {
                            href: _vm.baseURL + "api/private",
                            target: "_blank",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "icon icon-shape bg-gradient-warning rounded-circle text-white",
                            },
                            [_c("i", { staticClass: "ni ni-book-bookmark" })]
                          ),
                          _c("div", { staticClass: "media-body ml-3" }, [
                            _c(
                              "h6",
                              { staticClass: "heading text-warning mb-md-1" },
                              [
                                _vm._v(
                                  "\n                  API Privada\n                "
                                ),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "description d-none d-md-inline-block mb-0",
                              },
                              [
                                _vm._v(
                                  "\n                  Documentação da API Privada do SIRAF-NE\n                "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "base-dropdown",
                {
                  staticClass: "nav-item",
                  attrs: { "menu-classes": "dropdown-menu-xl" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        slot: "title",
                        href: "#",
                        "data-toggle": "dropdown",
                        role: "button",
                      },
                      slot: "title",
                    },
                    [
                      _c("i", { staticClass: "ni ni-ui-04 d-lg-none" }),
                      _c("span", { staticClass: "nav-link-inner--text" }, [
                        _vm._v("Outros"),
                      ]),
                    ]
                  ),
                  _c("li", [
                    _c("div", { staticClass: "dropdown-menu-inner" }, [
                      _c(
                        "a",
                        {
                          staticClass: "media d-flex align-items-center",
                          attrs: { href: "/RN-SAF" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "icon icon-shape bg-gradient-primary rounded-circle text-white",
                            },
                            [_c("i", { staticClass: "ni ni-single-copy-04" })]
                          ),
                          _c("div", { staticClass: "media-body ml-3" }, [
                            _c(
                              "h6",
                              { staticClass: "heading text-primary mb-md-1" },
                              [_vm._v("Selo SAF-RN")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "description d-none d-md-inline-block mb-0",
                              },
                              [
                                _vm._v(
                                  "\n                  Selo Agricultura Familiar\n                "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "ul",
            { staticClass: "navbar-nav align-items-lg-center ml-lg-auto" },
            [
              _c("li", { staticClass: "nav-item d-lg-block ml-lg-4" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-neutral btn-icon",
                    attrs: { href: _vm.baseURL + _vm.baseApiRota + "login" },
                  },
                  [
                    _c("span", { staticClass: "btn-inner--icon" }, [
                      _c("i", { staticClass: "fa fa-sign-in mr-2" }),
                    ]),
                    _c("span", { staticClass: "nav-link-inner--text" }, [
                      _vm._v("Entrar"),
                    ]),
                  ]
                ),
              ]),
              _c("li", { staticClass: "nav-item d-lg-block ml-lg-4" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-info btn-icon",
                    attrs: { href: _vm.baseURL + _vm.baseApiRota + "cadastro" },
                  },
                  [
                    _c("span", { staticClass: "btn-inner--icon" }, [
                      _c("i", { staticClass: "fa fa-user-plus mr-2" }),
                    ]),
                    _c("span", { staticClass: "nav-link-inner--text" }, [
                      _vm._v("Cadastro de Ofertante"),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }