var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section section-lg section-shaped overflow-hidden my-0" },
    [
      _vm._m(0),
      _c("div", { staticClass: "container py-0 pb-lg" }, [
        _c(
          "div",
          { staticClass: "row justify-content-between align-items-center" },
          [
            _vm._m(1),
            _c("div", { staticClass: "col-lg-6 mb-lg-auto" }, [
              _c(
                "div",
                {
                  staticClass:
                    "rounded shadow-lg overflow-hidden transform-perspective-right",
                },
                [
                  _c(
                    "b-carousel",
                    {
                      attrs: { id: "carousel1", controls: "", indicators: "" },
                    },
                    [
                      _c("b-carousel-slide", {
                        attrs: { "img-src": "img/theme/img-1-1200x1000.jpg" },
                      }),
                      _c("b-carousel-slide", {
                        attrs: { "img-src": "img/theme/img-2-1200x1000.jpg" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "shape shape-style-1 shape-default shape-skew" },
      [_c("span"), _c("span"), _c("span"), _c("span"), _c("span"), _c("span")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-lg-5 mb-5 mb-lg-0" }, [
      _c("h1", { staticClass: "text-white font-weight-light" }, [
        _vm._v("Bootstrap carousel"),
      ]),
      _c("p", { staticClass: "lead text-white mt-4" }, [
        _vm._v(
          "Argon Design System comes with four pre-built pages to help you get\n                    started faster. You can change the text and images and you're good to go."
        ),
      ]),
      _c(
        "a",
        {
          staticClass: "btn btn-white mt-4",
          attrs: {
            href: "https://demos.creative-tim.com/argon-design-system/docs/components/alerts.html",
          },
        },
        [_vm._v("See all components")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }