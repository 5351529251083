var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h2", { staticClass: "mt-lg mb-5" }, [
        _c("span", [_vm._v("Typography")]),
      ]),
      _c("h3", { staticClass: "h4 text-success font-weight-bold" }, [
        _vm._v("Headings"),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Heading 1")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("h1", { staticClass: "mb-0" }, [_vm._v("Argon Design System")]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Heading 2")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("h2", { staticClass: "mb-0" }, [_vm._v("Argon Design System")]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Heading 3")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("h3", { staticClass: "mb-0" }, [_vm._v("Argon Design System")]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Heading 4")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("h4", { staticClass: "mb-0" }, [_vm._v("Argon Design System")]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Heading 5")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("h5", { staticClass: "mb-0" }, [_vm._v("Argon Design System ")]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Heading 6")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("h6", { staticClass: "mb-0" }, [_vm._v("Argon Design System ")]),
        ]),
      ]),
      _c("h3", { staticClass: "h4 text-success font-weight-bold mt-md" }, [
        _vm._v("Display titles"),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Display 1")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("h1", { staticClass: "display-1 mb-0" }, [
            _vm._v("Argon Design System"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Display 2")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("h2", { staticClass: "display-2 mb-0" }, [
            _vm._v("Argon Design System"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Display 3")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("h3", { staticClass: "display-3 mb-0" }, [
            _vm._v("Argon Design System"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Display 4")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("h4", { staticClass: "display-4 mb-0" }, [
            _vm._v("Argon Design System"),
          ]),
        ]),
      ]),
      _c("h3", { staticClass: "h4 text-success font-weight-bold mt-md" }, [
        _vm._v("Specialized titles"),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Heading")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("h3", { staticClass: "heading mb-0" }, [
            _vm._v("Argon Design System"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Heading title")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("h3", { staticClass: "heading-title text-warning mb-0" }, [
            _vm._v("Argon Design System"),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Heading seaction")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("div", [
            _c("h2", { staticClass: "display-3" }, [
              _vm._v("Header with small subtitle "),
            ]),
            _c("p", { staticClass: "lead text-muted" }, [
              _vm._v(
                "According to the National Oceanic and Atmospheric Administration,\n                    Ted, Scambos, NSIDClead scentist, puts the potentially record maximum."
              ),
            ]),
          ]),
        ]),
      ]),
      _c("h3", { staticClass: "h4 text-success font-weight-bold mt-md" }, [
        _vm._v("Paragraphs"),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Paragraph")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("p", [
            _vm._v(
              "I will be the leader of a company that ends up being worth billions of dollars, because I got the\n                answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to\n                push possibilities, to show people, this is the level that things could be at."
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Lead text")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("p", { staticClass: "lead" }, [
            _vm._v(
              "I will be the leader of a company that ends up being worth billions of dollars,\n                because I got the answers. I understand culture. I am the nucleus. I think that’s a\n                responsibility that I have, to push possibilities, to show people, this is the level that things\n                could be at."
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Quote")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("blockquote", { staticClass: "blockquote" }, [
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a\n                    ante."
              ),
            ]),
            _c("footer", { staticClass: "blockquote-footer" }, [
              _vm._v("Someone famous in\n                    "),
              _c("cite", { attrs: { title: "Source Title" } }, [
                _vm._v("Source Title"),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Muted text")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("p", { staticClass: "text-muted mb-0" }, [
            _vm._v(
              "I will be the leader of a company that ends up being worth billions of\n                dollars, because I got the answers..."
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Primary text")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("p", { staticClass: "text-primary" }, [
            _vm._v(
              "I will be the leader of a company that ends up being worth billions of\n                dollars, because I got the answers..."
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Info text")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("p", { staticClass: "text-info mb-0" }, [
            _vm._v(
              "I will be the leader of a company that ends up being worth billions of\n                dollars, because I got the answers..."
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Success text")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("p", { staticClass: "text-success mb-0" }, [
            _vm._v(
              "I will be the leader of a company that ends up being worth billions of\n                dollars, because I got the answers..."
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Warning text")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("p", { staticClass: "text-warning mb-0" }, [
            _vm._v(
              "I will be the leader of a company that ends up being worth billions of\n                dollars, because I got the answers..."
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-sm-3" }, [
          _c(
            "small",
            { staticClass: "text-uppercase text-muted font-weight-bold" },
            [_vm._v("Danger text")]
          ),
        ]),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("p", { staticClass: "text-danger mb-0" }, [
            _vm._v(
              "I will be the leader of a company that ends up being worth billions of\n                dollars, because I got the answers..."
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }