var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-lg-3 col-md-6" },
          [
            _vm._m(0),
            _c(
              "base-checkbox",
              {
                staticClass: "mb-3",
                model: {
                  value: _vm.checkboxes.unchecked,
                  callback: function ($$v) {
                    _vm.$set(_vm.checkboxes, "unchecked", $$v)
                  },
                  expression: "checkboxes.unchecked",
                },
              },
              [_vm._v("\n                    Unchecked\n                ")]
            ),
            _c(
              "base-checkbox",
              {
                staticClass: "mb-3",
                model: {
                  value: _vm.checkboxes.checked,
                  callback: function ($$v) {
                    _vm.$set(_vm.checkboxes, "checked", $$v)
                  },
                  expression: "checkboxes.checked",
                },
              },
              [_vm._v("\n                    Checked\n                ")]
            ),
            _c(
              "base-checkbox",
              {
                staticClass: "mb-3",
                attrs: { disabled: "" },
                model: {
                  value: _vm.checkboxes.uncheckedDisabled,
                  callback: function ($$v) {
                    _vm.$set(_vm.checkboxes, "uncheckedDisabled", $$v)
                  },
                  expression: "checkboxes.uncheckedDisabled",
                },
              },
              [_vm._v("\n                    Unchecked\n                ")]
            ),
            _c(
              "base-checkbox",
              {
                staticClass: "mb-3",
                attrs: { disabled: "" },
                model: {
                  value: _vm.checkboxes.checkedDisabled,
                  callback: function ($$v) {
                    _vm.$set(_vm.checkboxes, "checkedDisabled", $$v)
                  },
                  expression: "checkboxes.checkedDisabled",
                },
              },
              [_vm._v("\n                    Checked\n                ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-lg-3 col-sm-6 mt-4 mt-md-0" },
          [
            _vm._m(1),
            _c(
              "base-radio",
              {
                staticClass: "mb-3",
                attrs: { name: "radio0" },
                model: {
                  value: _vm.radio.radio1,
                  callback: function ($$v) {
                    _vm.$set(_vm.radio, "radio1", $$v)
                  },
                  expression: "radio.radio1",
                },
              },
              [_vm._v("\n                    Unchecked\n                ")]
            ),
            _c(
              "base-radio",
              {
                staticClass: "mb-3",
                attrs: { name: "radio1" },
                model: {
                  value: _vm.radio.radio1,
                  callback: function ($$v) {
                    _vm.$set(_vm.radio, "radio1", $$v)
                  },
                  expression: "radio.radio1",
                },
              },
              [_vm._v("\n                    Checked\n                ")]
            ),
            _c(
              "base-radio",
              {
                staticClass: "mb-3",
                attrs: { name: "radio2", disabled: "" },
                model: {
                  value: _vm.radio.radio2,
                  callback: function ($$v) {
                    _vm.$set(_vm.radio, "radio2", $$v)
                  },
                  expression: "radio.radio2",
                },
              },
              [
                _vm._v(
                  "\n                    Disabled unchecked\n                "
                ),
              ]
            ),
            _c(
              "base-radio",
              {
                staticClass: "mb-3",
                attrs: { name: "radio3", disabled: "" },
                model: {
                  value: _vm.radio.radio2,
                  callback: function ($$v) {
                    _vm.$set(_vm.radio, "radio2", $$v)
                  },
                  expression: "radio.radio2",
                },
              },
              [
                _vm._v(
                  "\n                    Disabled checked\n                "
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-lg-3 col-sm-6 mt-4 mt-md-0" },
          [
            _vm._m(2),
            _c("base-switch", {
              model: {
                value: _vm.switches.off,
                callback: function ($$v) {
                  _vm.$set(_vm.switches, "off", $$v)
                },
                expression: "switches.off",
              },
            }),
            _c("span", { staticClass: "clearfix" }),
            _c("base-switch", {
              model: {
                value: _vm.switches.on,
                callback: function ($$v) {
                  _vm.$set(_vm.switches, "on", $$v)
                },
                expression: "switches.on",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-lg-3 col-sm-6 mt-4 mt-md-0" },
          [
            _vm._m(3),
            _c("base-slider", {
              model: {
                value: _vm.sliders.slider1,
                callback: function ($$v) {
                  _vm.$set(_vm.sliders, "slider1", $$v)
                },
                expression: "sliders.slider1",
              },
            }),
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c("base-slider", {
                  attrs: { range: { min: 0, max: 500 } },
                  model: {
                    value: _vm.sliders.slider2,
                    callback: function ($$v) {
                      _vm.$set(_vm.sliders, "slider2", $$v)
                    },
                    expression: "sliders.slider2",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row justify-content-center mt-md" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("h3", { staticClass: "h4 text-success font-weight-bold mb-4" }, [
            _vm._v("Menu"),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-lg-6" },
              [
                _vm._m(4),
                _c(
                  "base-nav",
                  {
                    attrs: {
                      expand: "",
                      effect: "dark",
                      type: "primary",
                      round: "",
                      title: "Menu",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "content-header",
                        fn: function ({ closeMenu }) {
                          return _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6 collapse-brand" }, [
                              _c("a", { attrs: { href: "./index.html" } }, [
                                _c("img", {
                                  attrs: { src: "img/brand/blue.png" },
                                }),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-6 collapse-close" },
                              [
                                _c("close-button", {
                                  attrs: { target: "nav-inner-primary" },
                                  on: { click: closeMenu },
                                }),
                              ],
                              1
                            ),
                          ])
                        },
                      },
                    ]),
                  },
                  [
                    _c("ul", { staticClass: "navbar-nav ml-lg-auto" }, [
                      _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          { staticClass: "nav-link", attrs: { href: "#" } },
                          [
                            _vm._v(
                              "Discover\n                                        "
                            ),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("(current)"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          { staticClass: "nav-link", attrs: { href: "#" } },
                          [_vm._v("Profile")]
                        ),
                      ]),
                      _c("li", { staticClass: "nav-item dropdown" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              href: "#",
                              id: "nav-inner-primary_dropdown_1",
                              role: "button",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false",
                            },
                          },
                          [_vm._v("Settings")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu dropdown-menu-right",
                            attrs: {
                              "aria-labelledby": "nav-inner-primary_dropdown_1",
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                              },
                              [_vm._v("Action")]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                              },
                              [_vm._v("Another action")]
                            ),
                            _c("div", { staticClass: "dropdown-divider" }),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                              },
                              [_vm._v("Something else here")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-lg-6 mt-4 mt-lg-0" },
              [
                _vm._m(5),
                _c(
                  "base-nav",
                  {
                    attrs: {
                      expand: "",
                      effect: "dark",
                      type: "success",
                      round: "",
                      title: "Menu",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "content-header",
                        fn: function ({ closeMenu }) {
                          return _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6 collapse-brand" }, [
                              _c("a", { attrs: { href: "./index.html" } }, [
                                _c("img", {
                                  attrs: { src: "img/brand/blue.png" },
                                }),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-6 collapse-close" },
                              [
                                _c("close-button", {
                                  attrs: { target: "nav-inner-primary" },
                                  on: { click: closeMenu },
                                }),
                              ],
                              1
                            ),
                          ])
                        },
                      },
                    ]),
                  },
                  [
                    _c("ul", { staticClass: "navbar-nav ml-lg-auto" }, [
                      _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link nav-link-icon",
                            attrs: { href: "#" },
                          },
                          [
                            _c("i", { staticClass: "ni ni-favourite-28" }),
                            _c(
                              "span",
                              { staticClass: "nav-link-inner--text d-lg-none" },
                              [_vm._v("Discover")]
                            ),
                          ]
                        ),
                      ]),
                      _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link nav-link-icon",
                            attrs: { href: "#" },
                          },
                          [
                            _c("i", { staticClass: "ni ni-notification-70" }),
                            _c(
                              "span",
                              { staticClass: "nav-link-inner--text d-lg-none" },
                              [_vm._v("Profile")]
                            ),
                          ]
                        ),
                      ]),
                      _c("li", { staticClass: "nav-item dropdown" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link nav-link-icon",
                            attrs: {
                              href: "#",
                              id: "nav-inner-success_dropdown_1",
                              role: "button",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false",
                            },
                          },
                          [
                            _c("i", { staticClass: "ni ni-settings-gear-65" }),
                            _c(
                              "span",
                              { staticClass: "nav-link-inner--text d-lg-none" },
                              [_vm._v("Settings")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu dropdown-menu-right",
                            attrs: {
                              "aria-labelledby": "nav-inner-success_dropdown_1",
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                              },
                              [_vm._v("Action")]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                              },
                              [_vm._v("Another action")]
                            ),
                            _c("div", { staticClass: "dropdown-divider" }),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                              },
                              [_vm._v("Something else here")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("small", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("Checkboxes"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("small", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("Radios"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("small", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("Toggle buttons"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("small", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("Sliders"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("small", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("With text"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("small", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("With icons"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }