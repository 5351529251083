export const userKey = ''
export const csrfToken = document.querySelector('meta[name="csrf-token"]').content

export const baseApiUrl = process.env.VUE_APP_BASE_API_URL
export const baseURL = process.env.VUE_APP_BASE_URL
export const baseApiRota = process.env.VUE_APP_BASE_API_ROTA
export const gRecapctha = process.env.VUE_APP_RECAPTCHA
export const deployMode = process.env.NODE_ENV

//export const baseURL = 'http://3.231.82.198/sirafne'
//export const baseURL = 'https://sirafnedev.lordi.uern.br'
//export const baseURL = 'https://sirafne.lordi.uern.br'

//export const baseApiUrl = 'http://3.231.82.198/api/v1'
//export const baseApiUrl = 'https://sirafnedev.lordi.uern.br/api/v1'
//export const baseApiUrl = 'https://sirafne.lordi.uern.br'

export default { baseApiUrl, csrfToken, baseURL, gRecapctha, deployMode, baseApiRota }
