var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profile-page" }, [
    _vm._m(0),
    _c("section", { staticClass: "section section-skew" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "card",
            {
              staticClass: "card-profile",
              attrs: { shadow: "", "no-body": "" },
            },
            [
              _c("div", { staticClass: "px-4" }, [
                _c("div", { staticClass: "row justify-content-start" }),
                _c("div", { staticClass: "text-center" }, [
                  _c("div", { staticClass: "container ct-example-row" }),
                ]),
                _vm.post
                  ? _c("div", { staticClass: "py-5 text-left" }, [
                      _c("div", { staticClass: "container" }, [
                        _c(
                          "div",
                          { staticClass: "row justify-content-center" },
                          [
                            _c("div", { staticClass: "col-lg-12" }, [
                              _c(
                                "h4",
                                { staticClass: "display-4 text-default" },
                                [_vm._v("Busca Detalhada:")]
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "row justify-content-left" }, [
                          _c(
                            "div",
                            { staticClass: "col-lg-3" },
                            [
                              _c(
                                "base-radio",
                                {
                                  staticClass: "mb-3",
                                  attrs: { name: "0" },
                                  model: {
                                    value: _vm.radioCategory,
                                    callback: function ($$v) {
                                      _vm.radioCategory = $$v
                                    },
                                    expression: "radioCategory",
                                  },
                                },
                                [_vm._v("\n                    Ofertantes")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-lg-3" },
                            [
                              _c(
                                "base-radio",
                                {
                                  staticClass: "mb-3",
                                  attrs: { name: "4" },
                                  model: {
                                    value: _vm.radioCategory,
                                    callback: function ($$v) {
                                      _vm.radioCategory = $$v
                                    },
                                    expression: "radioCategory",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    Grupos Informais"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-lg-3" },
                            [
                              _c(
                                "base-radio",
                                {
                                  staticClass: "mb-3",
                                  attrs: { name: "2" },
                                  model: {
                                    value: _vm.radioCategory,
                                    callback: function ($$v) {
                                      _vm.radioCategory = $$v
                                    },
                                    expression: "radioCategory",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    Associações e Cooperativas"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-lg-3" },
                            [
                              _c(
                                "base-radio",
                                {
                                  staticClass: "mb-3",
                                  attrs: { name: "3" },
                                  model: {
                                    value: _vm.radioCategory,
                                    callback: function ($$v) {
                                      _vm.radioCategory = $$v
                                    },
                                    expression: "radioCategory",
                                  },
                                },
                                [_vm._v("\n                    Ofertas")]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-lg-12" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.radioCategory == 0,
                                  expression: "radioCategory == 0",
                                },
                              ],
                              staticClass:
                                "row justify-content-left formMargin",
                            },
                            [
                              _c("div", { staticClass: "col-lg-12" }, [
                                _c(
                                  "h6",
                                  { staticClass: "display-6 text-default" },
                                  [
                                    _c(
                                      "base-checkbox",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { name: "OFERTANTES" },
                                        model: {
                                          value: _vm.checkEnable.category,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.checkEnable,
                                              "category",
                                              $$v
                                            )
                                          },
                                          expression: "checkEnable.category",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Filtrar por Categoria dos Ofertantes"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "b-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.checkEnable.category,
                                      expression: "checkEnable.category",
                                    },
                                  ],
                                  staticClass: "col-12 mb-3 ml-2",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-lg-3" },
                                    [
                                      _c(
                                        "base-checkbox",
                                        {
                                          staticClass: "mb-3",
                                          attrs: {
                                            name: "INDIVIDUAL",
                                            disabled: !_vm.checkEnable.category,
                                          },
                                          model: {
                                            value: _vm.checkProducer.individual,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.checkProducer,
                                                "individual",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "checkProducer.individual",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Individuais"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-lg-3" },
                                    [
                                      _c(
                                        "base-checkbox",
                                        {
                                          staticClass: "mb-3",
                                          attrs: {
                                            name: "INFORMAL",
                                            disabled: !_vm.checkEnable.category,
                                          },
                                          model: {
                                            value: _vm.checkProducer.informal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.checkProducer,
                                                "informal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "checkProducer.informal",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Em Grupos informais"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-lg-6" },
                                    [
                                      _c(
                                        "base-checkbox",
                                        {
                                          staticClass: "mb-3",
                                          attrs: {
                                            name: "FORMAL",
                                            disabled: !_vm.checkEnable.category,
                                          },
                                          model: {
                                            value:
                                              _vm.checkProducer.association,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.checkProducer,
                                                "association",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "checkProducer.association",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Em Associações e Cooperativas"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.radioCategory == 0 ||
                                    _vm.radioCategory == 4 ||
                                    _vm.radioCategory == 2,
                                  expression:
                                    "\n                    radioCategory == 0 ||\n                    radioCategory == 4 ||\n                    radioCategory == 2\n                  ",
                                },
                              ],
                              staticClass:
                                "row justify-content-left formMargin",
                            },
                            [
                              _c("div", { staticClass: "col-lg-12" }, [
                                _c(
                                  "h6",
                                  { staticClass: "display-6 text-default" },
                                  [
                                    _c(
                                      "base-checkbox",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { name: "INFORMAL" },
                                        model: {
                                          value: _vm.checkEnable.gender,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.checkEnable,
                                              "gender",
                                              $$v
                                            )
                                          },
                                          expression: "checkEnable.gender",
                                        },
                                      },
                                      [_vm._v("Filtrar por Gênero")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "b-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.checkEnable.gender,
                                      expression: "checkEnable.gender",
                                    },
                                  ],
                                  staticClass: "col-12 mb-3 ml-2",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-lg-3" },
                                    [
                                      _c(
                                        "base-checkbox",
                                        {
                                          staticClass: "mb-3",
                                          attrs: {
                                            name: "M",
                                            disabled: !_vm.checkEnable.gender,
                                          },
                                          model: {
                                            value: _vm.checkGender.male,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.checkGender,
                                                "male",
                                                $$v
                                              )
                                            },
                                            expression: "checkGender.male",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Masculino"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-lg-3" },
                                    [
                                      _c(
                                        "base-checkbox",
                                        {
                                          staticClass: "mb-3",
                                          attrs: {
                                            name: "F",
                                            disabled: !_vm.checkEnable.gender,
                                          },
                                          model: {
                                            value: _vm.checkGender.female,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.checkGender,
                                                "female",
                                                $$v
                                              )
                                            },
                                            expression: "checkGender.female",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Feminino"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.radioCategory == 0 ||
                                    _vm.radioCategory == 4 ||
                                    _vm.radioCategory == 2,
                                  expression:
                                    "\n                    radioCategory == 0 ||\n                    radioCategory == 4 ||\n                    radioCategory == 2\n                  ",
                                },
                              ],
                              staticClass:
                                "row justify-content-md-center formMargin",
                            },
                            [
                              _c("div", { staticClass: "col-lg-12" }, [
                                _c(
                                  "h6",
                                  { staticClass: "display-6 text-default" },
                                  [
                                    _c(
                                      "base-checkbox",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { name: "INFORMAL" },
                                        model: {
                                          value: _vm.checkEnable.age,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.checkEnable,
                                              "age",
                                              $$v
                                            )
                                          },
                                          expression: "checkEnable.age",
                                        },
                                      },
                                      [_vm._v("Filtrar por Idade")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "b-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.checkEnable.age,
                                      expression: "checkEnable.age",
                                    },
                                  ],
                                  staticClass: "col-12 mb-3 ml-2",
                                },
                                [
                                  _c("div", { staticClass: "col-sm-2" }, [
                                    _vm._v("\n                      De "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(parseInt(_vm.sliderAge[0])) + " "
                                      ),
                                    ]),
                                    _vm._v(" anos\n                    "),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-8" },
                                    [
                                      _c("base-slider", {
                                        attrs: {
                                          range: { min: 0, max: 100 },
                                          disabled: !_vm.checkEnable.age,
                                        },
                                        model: {
                                          value: _vm.sliderAge,
                                          callback: function ($$v) {
                                            _vm.sliderAge = $$v
                                          },
                                          expression: "sliderAge",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "col-sm-2" }, [
                                    _vm._v("\n                      Até "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(parseInt(_vm.sliderAge[1]))
                                      ),
                                    ]),
                                    _vm._v(" anos\n                    "),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.radioCategory == 0 ||
                                    _vm.radioCategory == 4 ||
                                    _vm.radioCategory == 2,
                                  expression:
                                    "\n                    radioCategory == 0 ||\n                    radioCategory == 4 ||\n                    radioCategory == 2\n                  ",
                                },
                              ],
                            },
                            [
                              _c("b-row", [
                                _c(
                                  "div",
                                  { staticClass: "col-lg-12 d-flex flex-wrap" },
                                  [
                                    _c(
                                      "h6",
                                      { staticClass: "display-7 text-default" },
                                      [
                                        _c(
                                          "base-checkbox",
                                          {
                                            staticClass: "mb-3",
                                            attrs: { name: "INFORMAL" },
                                            model: {
                                              value: _vm.checkEnable.funding,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.checkEnable,
                                                  "funding",
                                                  $$v
                                                )
                                              },
                                              expression: "checkEnable.funding",
                                            },
                                          },
                                          [_vm._v("Filtrar por Financiamento")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "b-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.checkEnable.funding,
                                      expression: "checkEnable.funding",
                                    },
                                  ],
                                  staticClass: "mb-3 ml-1",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-lg-2" },
                                    [
                                      _c(
                                        "base-checkbox",
                                        {
                                          staticClass: "mb-1",
                                          attrs: {
                                            name: "2",
                                            disabled: !_vm.checkEnable.funding,
                                          },
                                          model: {
                                            value: _vm.checkFunding.bid,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.checkFunding,
                                                "bid",
                                                $$v
                                              )
                                            },
                                            expression: "checkFunding.bid",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        BID"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-lg-2" },
                                    [
                                      _c(
                                        "base-checkbox",
                                        {
                                          staticClass: "mb-1",
                                          attrs: {
                                            name: "3",
                                            disabled: !_vm.checkEnable.funding,
                                          },
                                          model: {
                                            value: _vm.checkFunding.bird,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.checkFunding,
                                                "bird",
                                                $$v
                                              )
                                            },
                                            expression: "checkFunding.bird",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        BIRD"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-lg-2" },
                                    [
                                      _c(
                                        "base-checkbox",
                                        {
                                          staticClass: "mb-1",
                                          attrs: {
                                            name: "3",
                                            disabled: !_vm.checkEnable.funding,
                                          },
                                          model: {
                                            value: _vm.checkFunding.fida,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.checkFunding,
                                                "fida",
                                                $$v
                                              )
                                            },
                                            expression: "checkFunding.fida",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        FIDA"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.radioCategory == 0 ||
                                    _vm.radioCategory == 4 ||
                                    _vm.radioCategory == 2,
                                  expression:
                                    "\n                    radioCategory == 0 ||\n                    radioCategory == 4 ||\n                    radioCategory == 2\n                  ",
                                },
                              ],
                            },
                            [
                              _c("b-row", [
                                _c("div", { staticClass: "col-lg-12" }, [
                                  _c(
                                    "h6",
                                    { staticClass: "display-7 text-default" },
                                    [
                                      _c(
                                        "base-checkbox",
                                        {
                                          staticClass: "mb-3",
                                          attrs: { name: "INFORMAL" },
                                          model: {
                                            value: _vm.checkEnable.declaration,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.checkEnable,
                                                "declaration",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "checkEnable.declaration",
                                          },
                                        },
                                        [_vm._v("Filtrar por Autodeclaração")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _c(
                                "b-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.checkEnable.declaration,
                                      expression: "checkEnable.declaration",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-lg-12 d-flex flex-wrap",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-2" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-2",
                                              attrs: {
                                                name: "0",
                                                disabled:
                                                  !_vm.checkEnable.declaration,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkDeclaration.indigena,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkDeclaration,
                                                    "indigena",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkDeclaration.indigena",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                          Indigena"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-2" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-2",
                                              attrs: {
                                                name: "4",
                                                disabled:
                                                  !_vm.checkEnable.declaration,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkDeclaration
                                                    .quilombola,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkDeclaration,
                                                    "quilombola",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkDeclaration.quilombola",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                          Quilombola"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-2" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-2",
                                              attrs: {
                                                name: "4",
                                                disabled:
                                                  !_vm.checkEnable.declaration,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkDeclaration.cigano,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkDeclaration,
                                                    "cigano",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkDeclaration.cigano",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                          Cigano"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-2" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-2",
                                              attrs: {
                                                name: "4",
                                                disabled:
                                                  !_vm.checkEnable.declaration,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkDeclaration
                                                    .povos_de_terreiro,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkDeclaration,
                                                    "povos_de_terreiro",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkDeclaration.povos_de_terreiro",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                          Povos de Terreiro"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-2" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-2",
                                              attrs: {
                                                name: "4",
                                                disabled:
                                                  !_vm.checkEnable.declaration,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkDeclaration
                                                    .pescador_artesanal,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkDeclaration,
                                                    "pescador_artesanal",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkDeclaration.pescador_artesanal",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                          Pescador Artesanal"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-2" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-2",
                                              attrs: {
                                                name: "4",
                                                disabled:
                                                  !_vm.checkEnable.declaration,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkDeclaration.outro,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkDeclaration,
                                                    "outro",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkDeclaration.outro",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                          Outro"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm.radioCategory == 3
                          ? _c("div", { staticClass: "col-lg-12" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-content-left formMargin",
                                },
                                [
                                  _c("div", { staticClass: "col-lg-12" }, [
                                    _c(
                                      "h6",
                                      { staticClass: "display-6 text-default" },
                                      [
                                        _vm._v(
                                          "\n                      Filtrar por Produto\n                    "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-lg-3" },
                                    [
                                      _c(
                                        "base-radio",
                                        {
                                          staticClass: "mb-3",
                                          attrs: { name: "0" },
                                          model: {
                                            value: _vm.radioProduct,
                                            callback: function ($$v) {
                                              _vm.radioProduct = $$v
                                            },
                                            expression: "radioProduct",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Todos os produtos"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-lg-12" },
                                    [
                                      _c(
                                        "base-radio",
                                        {
                                          staticClass: "mb-3",
                                          attrs: { name: "1" },
                                          model: {
                                            value: _vm.radioProduct,
                                            callback: function ($$v) {
                                              _vm.radioProduct = $$v
                                            },
                                            expression: "radioProduct",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Um produto específico:"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "col-lg-12" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.selected,
                                            expression: "selected",
                                          },
                                        ],
                                        staticClass: "selectProduct",
                                        attrs: {
                                          label: "Selecione o produto:",
                                          disabled: _vm.checkEnable.allProduct,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.selected = $event.target
                                              .multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                        },
                                      },
                                      _vm._l(_vm.products, function (product) {
                                        return _c(
                                          "option",
                                          {
                                            key: product.value,
                                            domProps: { value: product.value },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(product.label) +
                                                "\n                      "
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-content-left formMargin",
                                },
                                [
                                  _c("div", { staticClass: "col-lg-12" }, [
                                    _c(
                                      "h6",
                                      {
                                        staticClass: "display-12 text-default",
                                      },
                                      [
                                        _c(
                                          "base-checkbox",
                                          {
                                            staticClass: "mb-3",
                                            attrs: { name: "INFORMAL" },
                                            model: {
                                              value: _vm.checkEnable.month,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.checkEnable,
                                                  "month",
                                                  $$v
                                                )
                                              },
                                              expression: "checkEnable.month",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Filtrar por Mês de produção"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "b-row",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.checkEnable.month,
                                          expression: "checkEnable.month",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-1" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-2 ml-3",
                                              attrs: {
                                                name: "0",
                                                disabled:
                                                  !_vm.checkEnable.month,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkProductMonth[0][1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkProductMonth[0],
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkProductMonth[0][1]",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Jan"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-1" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-2 ml-3",
                                              attrs: {
                                                name: "0",
                                                disabled:
                                                  !_vm.checkEnable.month,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkProductMonth[1][1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkProductMonth[1],
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkProductMonth[1][1]",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Fev"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-1" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-2 ml-3",
                                              attrs: {
                                                name: "4",
                                                disabled:
                                                  !_vm.checkEnable.month,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkProductMonth[2][1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkProductMonth[2],
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkProductMonth[2][1]",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Mar"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-1" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-1 ml-3",
                                              attrs: {
                                                name: "3",
                                                disabled:
                                                  !_vm.checkEnable.month,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkProductMonth[3][1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkProductMonth[3],
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkProductMonth[3][1]",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Abr"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-1" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-1 ml-3",
                                              attrs: {
                                                name: "2",
                                                disabled:
                                                  !_vm.checkEnable.month,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkProductMonth[4][1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkProductMonth[4],
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkProductMonth[4][1]",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Mai"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-1" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-1 ml-3",
                                              attrs: {
                                                name: "3",
                                                disabled:
                                                  !_vm.checkEnable.month,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkProductMonth[5][1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkProductMonth[5],
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkProductMonth[5][1]",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Jun"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-1" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-1 ml-3",
                                              attrs: {
                                                name: "3",
                                                disabled:
                                                  !_vm.checkEnable.month,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkProductMonth[6][1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkProductMonth[6],
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkProductMonth[6][1]",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Jul"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-1" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-1 ml-3",
                                              attrs: {
                                                name: "3",
                                                disabled:
                                                  !_vm.checkEnable.month,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkProductMonth[7][1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkProductMonth[7],
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkProductMonth[7][1]",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Ago"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-1" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-1 ml-3",
                                              attrs: {
                                                name: "3",
                                                disabled:
                                                  !_vm.checkEnable.month,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkProductMonth[8][1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkProductMonth[8],
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkProductMonth[8][1]",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Set"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-1" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-1 ml-3",
                                              attrs: {
                                                name: "3",
                                                disabled:
                                                  !_vm.checkEnable.month,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkProductMonth[9][1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkProductMonth[9],
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkProductMonth[9][1]",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Out"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-1" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-1 ml-3",
                                              attrs: {
                                                name: "3",
                                                disabled:
                                                  !_vm.checkEnable.month,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkProductMonth[10][1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkProductMonth[10],
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkProductMonth[10][1]",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Nov"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-lg-1" },
                                        [
                                          _c(
                                            "base-checkbox",
                                            {
                                              staticClass: "mb-1 ml-3",
                                              attrs: {
                                                name: "3",
                                                disabled:
                                                  !_vm.checkEnable.month,
                                              },
                                              model: {
                                                value:
                                                  _vm.checkProductMonth[11][1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.checkProductMonth[11],
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "checkProductMonth[11][1]",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Dec"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "row justify-content-left formMargin",
                          },
                          [
                            _c("div", { staticClass: "col-lg-12" }, [
                              _c(
                                "h6",
                                { staticClass: "display-6 text-default" },
                                [
                                  _c(
                                    "base-radio",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { name: "1" },
                                      model: {
                                        value: _vm.radioEnableState,
                                        callback: function ($$v) {
                                          _vm.radioEnableState = $$v
                                        },
                                        expression: "radioEnableState",
                                      },
                                    },
                                    [_vm._v("Exibir para Todos os Estados")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-lg-3" },
                              [
                                _c(
                                  "base-radio",
                                  {
                                    staticClass: "mb-3",
                                    attrs: { name: "" },
                                    model: {
                                      value: _vm.radioEnableState,
                                      callback: function ($$v) {
                                        _vm.radioEnableState = $$v
                                      },
                                      expression: "radioEnableState",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Filtrar por Estado:"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.radioEnableState,
                                    expression: "!radioEnableState",
                                  },
                                ],
                                staticClass: "mb-3 ml-2",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-lg-1" },
                                  [
                                    _c(
                                      "base-radio",
                                      {
                                        staticClass: "mb-3",
                                        attrs: {
                                          name: "6",
                                          disabled: !!_vm.radioEnableState,
                                        },
                                        model: {
                                          value: _vm.radioState,
                                          callback: function ($$v) {
                                            _vm.radioState = $$v
                                          },
                                          expression: "radioState",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../public/img/icons/flags/al.png"),
                                            width: "23px",
                                            height: "17px",
                                          },
                                        }),
                                        _vm._v("\n                      AL"),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-1" },
                                  [
                                    _c(
                                      "base-radio",
                                      {
                                        staticClass: "mb-3",
                                        attrs: {
                                          name: "7",
                                          disabled: !!_vm.radioEnableState,
                                        },
                                        model: {
                                          value: _vm.radioState,
                                          callback: function ($$v) {
                                            _vm.radioState = $$v
                                          },
                                          expression: "radioState",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../public/img/icons/flags/ba.png"),
                                            width: "23px",
                                            height: "17px",
                                          },
                                        }),
                                        _vm._v("\n                      BA"),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-1" },
                                  [
                                    _c(
                                      "base-radio",
                                      {
                                        staticClass: "mb-3",
                                        attrs: {
                                          name: "2",
                                          disabled: !!_vm.radioEnableState,
                                        },
                                        model: {
                                          value: _vm.radioState,
                                          callback: function ($$v) {
                                            _vm.radioState = $$v
                                          },
                                          expression: "radioState",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../public/img/icons/flags/ce.png"),
                                            width: "23px",
                                            height: "17px",
                                          },
                                        }),
                                        _vm._v("\n                      CE"),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-1" },
                                  [
                                    _c(
                                      "base-radio",
                                      {
                                        staticClass: "mb-3",
                                        attrs: {
                                          name: "1",
                                          disabled: !!_vm.radioEnableState,
                                        },
                                        model: {
                                          value: _vm.radioState,
                                          callback: function ($$v) {
                                            _vm.radioState = $$v
                                          },
                                          expression: "radioState",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../public/img/icons/flags/ma.png"),
                                            width: "23px",
                                            height: "17px",
                                          },
                                        }),
                                        _vm._v("\n                      MA"),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-1" },
                                  [
                                    _c(
                                      "base-radio",
                                      {
                                        staticClass: "mb-3",
                                        attrs: {
                                          name: "8",
                                          disabled: !!_vm.radioEnableState,
                                        },
                                        model: {
                                          value: _vm.radioState,
                                          callback: function ($$v) {
                                            _vm.radioState = $$v
                                          },
                                          expression: "radioState",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../public/img/icons/flags/pb.png"),
                                            width: "23px",
                                            height: "17px",
                                          },
                                        }),
                                        _vm._v("\n                      PB"),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-1" },
                                  [
                                    _c(
                                      "base-radio",
                                      {
                                        staticClass: "mb-3",
                                        attrs: {
                                          name: "5",
                                          disabled: !!_vm.radioEnableState,
                                        },
                                        model: {
                                          value: _vm.radioState,
                                          callback: function ($$v) {
                                            _vm.radioState = $$v
                                          },
                                          expression: "radioState",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../public/img/icons/flags/pe.png"),
                                            width: "23px",
                                            height: "17px",
                                          },
                                        }),
                                        _vm._v("\n                      PE"),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-1" },
                                  [
                                    _c(
                                      "base-radio",
                                      {
                                        staticClass: "mb-3",
                                        attrs: {
                                          name: "3",
                                          disabled: !!_vm.radioEnableState,
                                        },
                                        model: {
                                          value: _vm.radioState,
                                          callback: function ($$v) {
                                            _vm.radioState = $$v
                                          },
                                          expression: "radioState",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../public/img/icons/flags/pi.png"),
                                            width: "23px",
                                            height: "17px",
                                          },
                                        }),
                                        _vm._v("\n                      PI"),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-1" },
                                  [
                                    _c(
                                      "base-radio",
                                      {
                                        staticClass: "mb-3",
                                        attrs: {
                                          name: "4",
                                          disabled: !!_vm.radioEnableState,
                                        },
                                        model: {
                                          value: _vm.radioState,
                                          callback: function ($$v) {
                                            _vm.radioState = $$v
                                          },
                                          expression: "radioState",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../public/img/icons/flags/rn.png"),
                                            width: "23px",
                                            height: "17px",
                                          },
                                        }),
                                        _vm._v("\n                      RN"),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-1" },
                                  [
                                    _c(
                                      "base-radio",
                                      {
                                        staticClass: "mb-3",
                                        attrs: {
                                          name: "9",
                                          disabled: !!_vm.radioEnableState,
                                        },
                                        model: {
                                          value: _vm.radioState,
                                          callback: function ($$v) {
                                            _vm.radioState = $$v
                                          },
                                          expression: "radioState",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("../../public/img/icons/flags/se.png"),
                                            width: "23px",
                                            height: "17px",
                                          },
                                        }),
                                        _vm._v("\n                      SE"),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-lg-12 formMargin" },
                          [
                            _c(
                              "base-button",
                              {
                                attrs: { type: "primary", icon: "ni ni-world" },
                                on: {
                                  click: function ($event) {
                                    return _vm.loadData(true)
                                  },
                                },
                              },
                              [_vm._v("Clique para buscar")]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "row justify-content-left" }, [
                          _c("div", { staticClass: "col-lg-12" }, [
                            _c(
                              "div",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { id: "container" },
                              },
                              [
                                _c("chart", {
                                  attrs: {
                                    mapAddress: _vm.mapAddress,
                                    data: _vm.data,
                                    category: _vm.cat,
                                    init: _vm.init,
                                    end: _vm.end,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("b-table", {
                                  staticClass: "tableData",
                                  attrs: {
                                    striped: "",
                                    hover: "",
                                    responsive: "",
                                    fields: _vm.fields,
                                    items: _vm.results,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-lg-12 formMargin" },
                              [
                                _c(
                                  "base-button",
                                  {
                                    attrs: {
                                      type: "success",
                                      icon: "ni ni-cloud-download-95",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadData(
                                          _vm.downloadLink
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Fazer Download dos Dados em Formato de\n                      Planilha"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", [
                  _c("span", [
                    _c("p", { staticClass: "small" }, [
                      _vm._v(
                        "\n                *" +
                          _vm._s(_vm.cat) +
                          " cujo o endereço do cadastro ocorre em outro estado\n                fora da região especificada.\n              "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "section-profile-cover section-shaped my-0" },
      [
        _c("div", {
          staticClass: "shape shape-style-1 shape-primary shape-skew alpha-4",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }