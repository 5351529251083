var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "nav-tabs-navigation" }, [
      _c("div", { staticClass: "nav-tabs-wrapper" }, [_vm._t("nav")], 2),
    ]),
    _c("div", [_vm._t("content")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }