<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4"></div>
    </section>
    <section class="section section-skew">
      <div class="container col-6">
        <card shadow class="card-profile">
          <b-row>
            <b-col>
              <div class="mb-4">
                <b-alert :show="show" :variant="color" dismissible>
                  {{ mss_alert }}
                </b-alert>
              </div>
              <b-form @submit.prevent="validate">
                <label>Informe a chave de validação:</label>
                <b-form-input
                  id="input"
                  required
                  v-model="chave"
                  placeholder="informe a chave de validação"
                  maxlength="40"
                  description="Caracteres restantes"
                ></b-form-input>

                <b-button
                  @click="validate()"
                  :disabled="chave.length === 40 ? false : true"
                  class="mt-5"
                  variant="primary"
                  >Validar</b-button
                >
              </b-form>
            </b-col>
          </b-row>
        </card>
        <div>
          <vue-recaptcha
            ref="invisibleRecaptcha"
            @verify="onVerify"
            @expired="onExpired"
            size="invisible"
            :loadRecaptchaScript="true"
            :sitekey="recaptcha"
          >
          </vue-recaptcha>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { baseApiUrl, gRecapctha } from "@/global";
import VueRecaptcha from "vue-recaptcha";
import axios from "axios";
import loadpage from "../views/components/loadPage.vue";

export default {
  name: "Certificate",
  components: { loadpage, VueRecaptcha },
  data() {
    return {
      recaptcha: gRecapctha,
      chave: "",
      cont: 40,
      mss_alert: "",
      color: "primary",
      show: false,

      loading: false,
      post: null,
      error: null,
      errorMessage: "Ocorreu um erro ao processar sua requisição...",
    };
  },

  methods: {
    validate() {
      this.$refs.invisibleRecaptcha.execute();
    },
    onVerify(responseRecaptcha) {
      this.color = "primary";
      this.show = false;

      console.log("Verify: " + responseRecaptcha);
      let url=null;
      if(this.chave.includes('DEC')){
         url = baseApiUrl + "/users/receipt-validation";
      }else{
         url = baseApiUrl + "/homologations/certificate-validation";
      }

      let payload = {
        code: this.chave,
        recaptcha: responseRecaptcha,
      };

      const response = axios.post(url, payload);  
      response.then((resp) => {
        this.resetRecaptcha();
        console.log(resp.data.is_valid);

        if (resp.data.is_valid) {
          this.mss_alert =
            "Certificado Válido! Chave informada:  " + this.chave;
          this.color = "success";
          this.show = true;
          this.chave = "";
        } else {
          this.mss_alert =
            "Certificado Inválido! Chave informada: \n" + this.chave;
          this.color = "danger";
          this.show = true;
          this.chave = "";
        }
      });
      response.catch((err) => {
        this.resetRecaptcha();
        this.mss_alert =
          "Não foi possível validar a chave informada! Erro: " +
          err.response.status;
        this.color = "danger";
        this.show = true;
        this.chave = "";
      });
    },
    onExpired: function () {
      console.log("Expired");
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.$refs.invisibleRecaptcha.reset(); // Direct call reset method
    },
  },
  mounted() {
    if (this.$route.query.chave) {
      this.chave = this.$route.query.chave;
      this.validate();
    }
  },
};
</script>
<style>
#input {
  font-size: 1.6em;
}
.profile-page .card-profile {
  margin-top: -400px !important;
}

.loading {
  height: 500px;
  text-align: center;
  padding-top: 50px;
}
</style>
