<template>
  <div id="app">
    <base-alert class="text-center" type="warning" dismissible v-if="dev">
      <span class="alert-inner--text text-dark "
        ><strong>Atenção! 
        <br/>
          Você está utilizando uma instância de testes
        do SIRAF NE.
        <br/>
        Todos os dados presentes neste sistema não são oficiais e
        podem ser editados e/ou apagados a qualquer momento sem aviso
        prévio.</strong>
        </span
      >
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </base-alert>
    <router-view name="header"></router-view>

    <main>
      <fade-transition origin="center" mode="out-in" :duration="250">
        <router-view />
      </fade-transition>
    </main>

    <router-view name="footer"></router-view>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import { csrfToken, deployMode } from "./global";
export default {
  components: {
    FadeTransition,
    csrfToken,
  },
  data() {
    return {
      dev: false,
    };
  },
  created() {
    if (deployMode == "production") {
      this.dev = false;
    } else {
      this.dev = true;
    }
  },
};
</script>
