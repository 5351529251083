var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "ul",
      { staticClass: "navbar-nav align-items-lg-center ml-lg-auto" },
      [
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            { staticClass: "nav-link nav-link-icon", attrs: { href: "#" } },
            [
              _c("i", { staticClass: "fa fa-facebook-square" }),
              _c("span", { staticClass: "nav-link-inner--text d-lg-none" }, [
                _vm._v("Share"),
              ]),
            ]
          ),
        ]),
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            { staticClass: "nav-link nav-link-icon", attrs: { href: "#" } },
            [
              _c("i", { staticClass: "fa fa-twitter" }),
              _c("span", { staticClass: "nav-link-inner--text d-lg-none" }, [
                _vm._v("Tweet"),
              ]),
            ]
          ),
        ]),
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            { staticClass: "nav-link nav-link-icon", attrs: { href: "#" } },
            [
              _c("i", { staticClass: "fa fa-pinterest" }),
              _c("span", { staticClass: "nav-link-inner--text d-lg-none" }, [
                _vm._v("Pin"),
              ]),
            ]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }